.messenger-chat {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 20px;
  text-align: center;
  width: 175px;
  z-index: 1000;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px 20px 0px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  @media (max-width: 992px) {
    background: none;
    right: 0;
    width: 100%;
  }
  .first-row {
    margin: 0px auto;
    font-size: 15px;
    color: #FF9326;
    @include animation(0s, 5s, message-us);
    @media (max-width: 992px) {
      display: none;
    }
  }
  .second-row {
    margin: 0px auto 0;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    @include animation(0.5s, 5s, message-us);
    @media (max-width: 992px) {
      display: none;
    }
  }
  .messenger-row {
    margin-top: 6px;
    background-color: #1786FC;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    line-height: 40px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: -0.4px;
    @media (max-width: 992px) {
      margin: 0 auto;
      width: 100px;
      float: right;
    }
  }
}