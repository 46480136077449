$highlight: #00A0C8;
$highlight_dp: #337AB7;
$yellow: #F9B12D;
$gray-extra-light: #f1f1f3;
$gray-light: #ECECEE;
$gray: #D9D9D9;
$gray-disabled: #DDDDDD;
$link: #006C9F;
$dark: #464759;
$orange: #FC5800;
$red: #AA2233;
$blue: #006C9F;
$blue-light: #00bae1;

$footer: #44475a;

/***** RÉGI *****/
/*$text: #44444B;
$gray-dark: #666666;
$gray-background: #445567;*/

/***** ÚJ *****/
$text: #44475a;
$gray-dark: #44475a;
$gray-not-so-dark: #959595;
$gray-background: #44475a;


/*** meta-k színe is átállt az új padlizsán színre ***/

$green: #00B285;
$orangeCTA: #ff9326;
/*$orangeCTA: #ec9539;*/
$purple-light: #686D88;
$white: #ffffff;
$voucher_hl: #0099cc;
$muted: #909095;
$stars: #f3d34c;

@mixin gradient {
    background: -moz-linear-gradient(top,  rgba(17,39,68,0.9) 0%, rgba(78,86,124,0.9) 100%);
    background: -webkit-linear-gradient(top,  rgba(17,39,68,0.9) 0%,rgba(78,86,124,0.9) 100%);
    background: linear-gradient(to bottom,  rgba(17,39,68,0.9) 0%,rgba(78,86,124,0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6112744', endColorstr='#e64e567c',GradientType=0 );
}


// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $left $top $blur $color;
        -moz-box-shadow:inset $left $top $blur $color;
        box-shadow:inset $left $top $blur $color;
    } @else {
        -webkit-box-shadow: $left $top $blur $color;
        -moz-box-shadow: $left $top $blur $color;
        box-shadow: $left $top $blur $color;
    }
}

@mixin keyframe ($animationName) {
@-webkit-keyframes #{$animationName} {
    @content;
}
@-moz-keyframes #{$animationName} {
    @content;
}
@-o-keyframes #{$animationName} {
    @content;
}
@keyframes #{$animationName} {
    @content;
}
}

@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: both; /* this prevents the animation from restarting! */
    -webkit-animation-iteration-count: infinite;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: both; /* this prevents the animation from restarting! */
    -moz-animation-iteration-count: infinite;

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: both; /* this prevents the animation from restarting! */
    -o-animation-iteration-count: infinite;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: both; /* this prevents the animation from restarting! */
    animation-iteration-count: infinite;

}


@include keyframe(message-us) {
    0% {
        font-size: 100%;
        opacity: 1;
        width: 100%;
        margin-left: 0px;
    }

    90% {
        font-size: 100%;
        opacity: 1;
        width: 100%;
        margin-left: 0px;
    }

    95% {
        font-size: 110%;
        opacity: 0.5;
        width: 110%;
        margin-left: -10px;
    }

    100% {
        font-size: 100%;
        opacity: 1;
        width: 100%;
        margin-left: 0px;
    }
}

