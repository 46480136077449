.detail-header {
    border-bottom: 1px solid $gray-light;
    padding-bottom: 5px;
    margin-bottom: 10px;
    h1 {
        margin: 5px 0;
        font-size: 25px;
        .icon-star {
            white-space: nowrap;
            font-size: 18px;
            color: $stars;
        }
    }
    h2 {
        font-size: 18px;
        margin: 7px 0;
    }
}

.detail-image {
    margin-bottom: 20px;
    position: relative;
}
.tab-pane {
    .percent {
        position: absolute;
        top: -4px;
        left: 10px;
        background-color: $orangeCTA;
        border-radius: 5px;
        color: $white;
        font-weight: bold;
        line-height: 43px;
        width: 43px;
        text-align: center;
        z-index: 1000;
        border: 1px solid $orangeCTA;
        background: -moz-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
        @include rotate(-15);
        @media (max-width: 992px) {
            top: -6px;
            line-height: 43px;
            width: 43px;
            font-size: 16px;
            left: 0px;
            bottom: inherit;
            right: inherit;
        }
    }
}
.detail-image-slider {
    display: none;
    margin-bottom: 20px;
    .slick-slide {
        padding: 0 5px;
        img {
            max-width: 100%;
            height: auto;
        }
    }

}

.found-packages {
    margin: 0 10px;
    padding-right: 0;
    li {
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        &:last-of-type {
          margin-bottom: 10px;
        }
    }
    .btn-sm {
        height: 26px;
    }
    span.package-name {
        display: table-cell;
        vertical-align: middle;
        width: 600px;
        padding: 5px 0;

        .package-price {
            font-weight: bold;
            transition: all .25s ease-in-out;
            float: right;
            margin-right: 10px;
        }
    }
    .forward-action {
        text-align: center;
        width: 29px;
        @media (max-width: 992px) {
            width: 15%;
        }
        background-color: $gray-background;
        color: $white;
        font-size:14px;
        display: table-cell;
        height: 43px;
        vertical-align: middle;
        z-index: 100;
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        cursor: pointer;
        a {
            display: inline;
            height: 100%;
            width: 100%;
            &:hover, &:active, &:visited {
                color: $white;
            }
        }
        &:hover {
            background-color: $orangeCTA;
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
    }
}

.detail-offer {
    border-bottom: 1px solid $gray-light;
    display: table;
    width: 100%;
    .image, .description, .price-wrapper {
        display: table-cell;
        position: relative;
        vertical-align: top;
        padding: 15px;
        white-space: nowrap;
        @media (max-width: 992px) {
            white-space: normal;
        }
        .zoom-icon {
            display: none;
            @media (max-width: 992px) {
                display: inline;
            }
        }
        .percent {
            top: 4px;
            right: -3px;
            position: absolute;
            background-color: $orangeCTA;
            border-radius: 5px;
            color: $white;
            font-weight: bold;
            line-height: 28px;
            width: 28px;
            text-align: center;
            z-index: 1000;
            border: 1px solid $orangeCTA;
            background: -moz-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
            background: linear-gradient(to bottom, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
            @include rotate(-15);
            @media (max-width: 992px) {
                top: 10px;
                left: 10px;
                bottom: inherit;
                right: inherit;
            }
        }
    }
    .image {
        width: 200px;
        padding-left: 0;
        height: 153px;
        overflow: hidden;
        img {
            max-width: 100%;
        }
    }
    .image_label {
        padding: 4px;
        margin: 0px;
        position: relative;
        .zoom-button {
            position: absolute;
            color: $white;
            z-index: 10;
            opacity: 0.85;
            text-align: center;
            &.btn-primary {
                &:hover {
                    border-color: $orangeCTA;
                    background: $orangeCTA;
                }
                border-color: $orangeCTA;
                background: $orangeCTA;
            }
            span {
                font-size: 20px;
                font-style: normal;
            }
        }
        .zoom-button {
            top: 42px;
            left: 65px;
        }
        &:hover {
            img {
                opacity: 1;
            }
            .zoom-button, .add-button {
                display: block;
                opacity: 1;
            }
        }
    }
    .description {
        margin-left: 220px;
        width: 775px;
        h3 {
            margin-top: 0;
        };
        p {
            display: block;
           /* max-width: 500px;*/
            white-space: normal;
            word-break: break-word;
        }
        .description-services {
            float: left;
            line-height: 30px;
            padding: 5px 5px;
            /*padding: 5px 10px;
            border-right: 1px solid $gray;*/
            img {
                margin-right: 3px;
            }
        }
        .description-services:last-of-type {
            border: none;
        }
        .btn-primary {
            height: initial;
            z-index: 99;
        }
    }
    .price-wrapper {
        text-align: right;
        .price {
            display: block;
            white-space: nowrap;
            b {
                font-size: 22px;
                display: inline-block;
            }

        }
        @media (max-width: 992px) {
            float: right;
        }
    }
    .action {
        margin-top: 5px;
        padding-top: 6px;
        padding-bottom: 3px;
    }
    &.modal-body {
        .description-services {
            line-height: 23px;
            padding: 5px 5px;
            img {
                width: 22px;
                margin-right: 3px;
            }
        }
    }
    .forward-action {
        text-align: center;
        width: 29px;
        @media (max-width: 992px) {
            width: 15%;
        }
        background-color: $gray-background;
        color: $white;
        font-size:14px;
        display: table-cell;
        vertical-align: middle;
        z-index: 100;
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        cursor: pointer;
        a {
            display: block;
            height: 100%;
            width: 100%;
            &:hover, &:active, &:visited {
                color: $white;
            }
        }
        &:hover {
            background-color: $orangeCTA;
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
    }
}

.detail-offer-header {
    background: $gray-light;
    padding: 8px;
    margin: 0;
}

.package-light-info {
  /*color: $gray-not-so-dark;*/
  ul {
    padding-left: 10px;
    li {
      ul {
        padding-left: 40px;
        li {
          list-style-type: circle;
        }
      }
      padding: 3px 0;
      list-style-type: none;
      .fa {
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        text-align: center;
      }
      img.serviceimage {
        margin-right: 10px;
      }
    }
  }
}
.highlight-flexible {
    height: initial;
    padding: 10px;
    margin: 10px 5px;
    border: 2px solid $orangeCTA;
}

.detail-booking {
    border: 2px solid $gray-background;
    background: $gray-light;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
    display: table;
    width: 100%;
    margin-bottom: 10px;

    .price {
        font-size: 24px;
        font-weight: bold;
        display: inline-block;
        padding: 4px 20px 0 0;
        -webkit-transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -o-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
        &.price-loading:not(:required):before {
            -webkit-transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -o-transition: all .25s ease-in-out;
            transition: all .25s ease-in-out;
            content: '';
            display: inline-block;
            font-size: 7px;
            width: 7px;
            height: 7px;
            margin: 10px 20px;
            float: left;
            -webkit-animation: spinner 1500ms infinite linear;
            -moz-animation: spinner 1500ms infinite linear;
            -ms-animation: spinner 1500ms infinite linear;
            -o-animation: spinner 1500ms infinite linear;
            animation: spinner 1500ms infinite linear;
            border-radius: 0.5em;
            -webkit-box-shadow: rgba(68, 68, 75, 1) 1.5em 0 0 0, rgba(68, 68, 75, 1) 1.1em 1.1em 0 0, rgba(68, 68, 75, 1) 0 1.5em 0 0, rgba(68, 68, 75, 1) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(68, 68, 75, 1) 0 -1.5em 0 0, rgba(68, 68, 75, 1) 1.1em -1.1em 0 0;
            box-shadow: rgba(68, 68, 75, 1) 1.5em 0 0 0, rgba(68, 68, 75, 1) 1.1em 1.1em 0 0, rgba(68, 68, 75, 1) 0 1.5em 0 0, rgba(68, 68, 75, 1) -1.1em 1.1em 0 0, rgba(68, 68, 75, 1) -1.5em 0 0 0, rgba(68, 68, 75, 1) -1.1em -1.1em 0 0, rgba(68, 68, 75, 1) 0 -1.5em 0 0, rgba(68, 68, 75, 1) 1.1em -1.1em 0 0;
        }
    }
    .action {
        padding: 8px 20px 6px 20px;
        font-size: 17px;
        font-weight: bold;
        float: right;
    }
    .unique-offer-link {
        cursor: pointer;
        display: inline-block;
        width: 150px;
        padding: 10px 10px 0;
        text-align: center;
        float: right;
    }
    &__price {
        padding: 10px;
        border-top: 1px solid $gray;
        &:before, &:after {
            display: table;
            content: '';
            clear: both;
        }
    }
    &__fields {
        display: table;
        width: 100%;
        margin: 10px auto;
        .field-dates {
            @media (max-width: 992px) {
                font-size: 12px;
            }
        }
        .date-div {
            position: relative;
            .fill-dates {
                @include box-shadow(1px, 1px, 12px, $dark);
                color: $white;
                background: $blue;
                bottom: 84px;
                position: absolute;
                width: 180px;
                left: 40px;
                z-index: 1000;
                border-radius: 2px;
                @media (max-width: 992px) {
                    bottom: -36px;
                    left: 20px;
                }
                .arrow-up {
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid $blue;
                    position: absolute;
                    top: -7px;
                    left: 84px;
                }
                table {
                    margin: 3px 0;
                }
                .text-td {
                    padding: 0px 8px;
                    font-size: 14px;
                    line-height: 17px;
                    @media (max-width: 992px) {
                        font-size: 13px;
                    }
                }
                .icon-td {
                    padding: 5px 8px;
                    text-align: center;
                    border-right: 1px solid $white;
                    font-size: 25px;
                }
            }
        }
        label {
            font-size: 12px;
            text-transform: uppercase;
        }
        .form-group {
            margin-bottom: 0px;
            .white-radio-group {
                font-size: 13px;
                background: $white;
                width: 100%;
                padding: 0px !important;
                .radio-record {
                    padding: 5px;
                    margin: 8px 8px 0;
                    border: 1px solid #efefef;
                    &:last-of-type {
                        margin: 8px;
                    }input[type=radio] {
                        margin: 0px 10px !important;
                    }
                    label {
                        margin-bottom: 0px;
                        text-transform: none;
                        display: inline;
                        &.bold {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .form-group:not(.input-group) {
            /*width: 30%;
            display: table-cell;*/
            padding: 5px 10px;
            &.small-fg {
                width: 20%;
            }
             .form-control {
                padding: 4px 5px 2px 5px;
                height: 30px;
                font-size: 13px;
                 &.field-dates {
                   @media (max-width: 992px) {
                     font-size: 12px;
                   }
                 }
                background: #FFF !important;
                &.persons-dropdown {
                    padding: 0 8px;
                }
            }
        }
    }
    .detail-booking-offer-info {
        font-size: 13px;
        padding: 10px;
      text-align: left;
        color: $white;
        background: $gray-background;
    }
}

.persons-dropdown {
    .form-group {
        margin: 0 0 10px 0;
    }
}

.detail-feature-container {
    overflow: auto;
    padding-top: 1px;
    margin-bottom: 30px;
    .detail-features {
        border: 2px solid $highlight_dp;
        li {
            text-align: center;
            img {
                width: 20px;
            }
            div {
                display: inline-block;
                margin: 0 auto;
                white-space: nowrap;
                overflow:hidden !important;
                text-overflow: ellipsis;
                width: 100%;
                font-size: 14px;
                height: 25px;
            }
        }
    }
    .detail-features-category {
        li {
            a {
                font-size: 15px;
                color: $text;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            border-top: 1px solid #FFF;
            border-right: 2px solid #FFF;
            &:last-of-type {
                border-right: none;
            }
            color: $text;
            background: $gray-light;
            &.active {
                color: $white;
                background: $highlight_dp;
                a {
                    color: $white;
                }
            }
        }
    }
    .detail-features, .detail-features-category {
        &:before, &:after {
            clear: both;
            display: table;
            content: '';
        }
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-weight: bold;
        /*border-bottom: 1px solid $gray-light;*/
        li {
            padding: 4px 10px 4px;
            margin: 0;
            width: (100%/5);
            display: block;
            float: left;
            line-height: 28px;
            i {
                font-size: 20px;
                margin-right: 10px;
                width: 20px;
            }
            &.more {
                float: none;
                cursor: pointer;
                display: block;
                width: 100%;
                text-align: right;
                background: none;
                padding: 5px 20px 0;
                font-weight: normal;
                font-style: italic;
            }
            @media (max-width: 992px) {
                width: (100%/3);
            }
        }
    }
    .detail-features {
        li {
            padding: 5px 5px 5px;
            line-height: 15px;
        }
    }
}

.js-modal-accordion.info-accodion {
    overflow: hidden;
    height: 90px;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    position: relative;
}

.reservation-accordion-opener {
    text-align: center;
    //padding: 40px 0 0 0;
    color: #006C9F;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    height: 30px !important;
    bottom: 0;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 67%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 67%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
    cursor: pointer;

    span {
        padding: 5px 15px 2px 15px;
        border-radius: 10px;
        font-size: 12px;
        background: #445567;
        color: #FFF;
    }
}

.detail-page .map {
    margin: 15px 0 0 0;
}

.detail-page h3 {
    font-weight: bold;
}

.detail-rating {
    cursor: pointer;
    text-align: center;
    float: right;
    .badge {
        background: $orangeCTA;
        font-size: 20px;
        color: #FFF;
        border-radius: 30px;
        padding: 10px 15px;
        margin: 0 0 0 10px;
    }
    a {
        display: block;
    }
}

.detail-contact {
    padding-top: 10px;
    line-height: 34px;
    a, span {
        font-size: 14px;
    }
    .contact-row.phone-data {
      border: 1px solid $orangeCTA;
      border-radius: 20px;
      line-height: 21px;
      padding: 2px 9px 2px 4px;
      float: right;
      a span, i {
        font-size: 14px
      }
    }
    i.icon-map {
        padding: 5px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid $gray;
        margin-right: 10px;
        margin-left: 10px;
        @media (max-width: 992px) {
            margin-left: 0;
        }
    }
    i.icon-phone {
      padding: 5px;
      width: 40px;
      height: 40px;
      color: $orangeCTA;
      @media (max-width: 992px) {
        margin-left: 0;
      }
    }
    @media (max-width: 992px) {
        line-height: 24px;
        span.contact-row {
            display: block;
        }
    }

}

.detail-image-loader {
    background: transparentize($text, .3) url('../img/loading.svg') no-repeat center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    opacity: 0;
    transition-duration: .3s;
}

.detail-image.loading .detail-image-loader {
    opacity: 1;
}

.detail-accordion {
    h3 {
        cursor: pointer;
    }
    > div {
        display: none;
        position: relative;
        padding-left: 18px;
    }
    &-half div {
        height: 160px;
        overflow :hidden;
    }
    &-half {
        .detail-accordion-opener-up {
            display: none;
        }
        .detail-accordion-opener-down {
            display: inline-block;
        }
    }
    &-half.open {
        .detail-accordion-opener-up {
            display: inline-block;
        }
        .detail-accordion-opener-down {
            display: none;
        }
    }
    &-half .detail-accordion-opener-down {
        display: inline-block;;
    }
    &-half.open .detail-accordion-opener-down {
        display: none;
    }
    &.show > div {
        display: block;
    }
    &-opener {
        text-align: center;
        padding: 40px 0 0 0;
        color: $link;
        position: absolute;
        left: 0;
        width: 100%;
        height: 80px !important;
        bottom: 0;
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 67%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 67%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
        cursor: pointer;
        span {
            display: inline-block;
            padding: 5px 15px 2px 15px;
            border-radius: 10px;
            font-size: 12px;
            background: $gray-background;
            color: #FFF;
        }
    }
}

.detail-poi-container {
    display: inline-block;
    clear: both;
    .detail-poi-row {
        padding-left: 10px;
        margin-bottom: 5px;
        clear: both;
        display: block;
        line-height: 40px;
        .detail-poi-icon {
            float: left;
            width: 40px;
            text-align: center;
        }
        .detail-poi-data {
            float: left;
        }
    }
}

.modal-accordion {
    border-bottom: 1px solid $gray;
    padding: 0 0 10px 0;
    display: none;
    &.accept-accordion {
        margin: 10px 10px 0;
        background: $gray-extra-light;
        padding: 10px;
        border-bottom: 1px solid $gray;
        border-top: 1px solid $gray;
    }
}

.package-description {
  //height: 93px;
    overflow: hidden;
    position: relative;

    &__cover {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 65%, white 100%);
        width: 100%;
        height: 140px;
        position: absolute;
        top: 0;
        text-align: center;
    }
}

.mobile-price-wrapper {
  .price {
    margin-bottom: 5px;
    font-size: 16px;
  }
}