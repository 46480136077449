.winter-border {
  border: 2px solid #fff !important;
}

.winter-border-date {
  .input-group {
    border: 2px solid #fff !important;
  }
  .input-group-addon, input {
    height: 38px !important;
  }
}

@media only screen and (min-width: 995px) {
  .winter-header {
    background-image: url('/img/top_bg.png') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #124761 !important;
  }
}

.header-info-bar {
  background: #ECECEE;
  text-align: right;
  padding: 4px 0;
  font-size: 12px;
  span {
    margin-left: 20px;
    display: inline-block;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.scrolled .header-info-bar {
  display: none;
}

.menu {
  text-align: left;

  nav, a {
    display: inline-block;
    @media (max-width: 992px) {
      display: block;
    }
    vertical-align: middle;
  }
  a {
    padding: 10px 9px;
    color: $white;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    @media (max-width: 992px) {
      color: $gray-dark;
    }
    &.bordered {
      border: 1px solid $orangeCTA;
      border-radius: 3px;
      padding: 5px 10px;
      @media (max-width: 992px) {
        padding: 10px 9px;
        border: 0 none;
      }
    }
    &.maldiv {
      background: url('../img/maldiv_menu_bg.png') no-repeat top left;
      color: #000;
      padding-left: 32px;
      font-weight: bold;
      &:hover, &:focus, &:active {
        background: url('../img/maldiv_menu_bg.png') no-repeat top left;
        padding-left: 32px;
        color: #000;
        font-weight: bold;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
        @media (max-width: 992px) {
          background: none;
          padding: 10px;
          font-weight: normal;
          color: $gray-dark;
        }
      }
      @media (max-width: 992px) {
        background: none;
        padding: 10px;
        font-weight: normal;
        color: $gray-dark;
      }
    }
    &:focus, &:active {
      color: $white;
      @media (max-width: 992px) {
        color: $gray-dark;
      }
    }
    &:hover {
      background: $orangeCTA;
      color: $white;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    &.nopadding {
      padding: 10px 0;
    }
    &.smallpadding {
      padding: 10px 5px 8px 5px;
    }
  }
  a.logo_link {
    padding: 0;
  }
  .menu-additional {
    /*margin-left: -33px;*/
    .phone {
      color: $orangeCTA;
      font-size: 17px;
      padding: 8px 9px;
      &:hover {
        background-color: inherit;
        color: $white;
      }
    }
    .phone-available {
      font-size: 13px;
      padding: 0px 3px;
      &:hover {
        background-color: inherit;
      }
    }
  }
  @media (max-width: 992px) {
    nav {
      width: 100%;
    }
    a {
      display: block;
      border-bottom: 1px solid $gray;
      font-size: 18px;
      &.logo_link {
        display: none;
      }
      &:first-child {
        border-top: 1px solid $gray;
      }
    }
    a.inline {
      text-align: center;
      border: none;
      font-size: 20px;
    }
    .map-link {
      display: none;
    }
    .menu-additional {
      display: inline-block;
      margin-left: 0px;
      .phone-available {
        div {
          float: left;
        }
        font-size: 17px;
        padding: 0px 3px;
        display: inline-grid;
        &:hover {
          background-color: inherit;
        }
      }
    }
    .menu-additional a {
      float: left;
      padding: 10px 8px;
      font-size: 18px;
      border: 0 none;
      &.smallpadding {
        padding: 6px 12px 8px 12px;
      }
    }
  }
}

.map-link {
  background: url('../img/worldmap.png') no-repeat top right;
  background-size: contain;
  width: 75px;
  height: 31px;
  display: inline-block;
  padding: 8px 10px;
  margin: 0 0 0 15px;
  border-left: 1px solid $gray;
}

.header-wrapper {
  height: 138px;
  /* z-index: 1070;*/
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 992px) {
    height: auto;
  }
}

.scrolled .header-wrapper {
  position: fixed;
  top: 0;
}

.header {
  padding: 40px 0;
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  // background: transparentize($text, .1);
  // @include gradient;
  //background: -moz-linear-gradient(top,  rgba(236,236,238,1) 0%, rgba(236,236,238,0.5) 100%);
  //background: -webkit-linear-gradient(top,  rgba(236,236,238,1) 0%,rgba(236,236,238,0.5) 100%);
  //background: linear-gradient(to bottom,  rgba(236,236,238,1) 0%,rgba(236,236,238,0.5) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#122441', endColorstr='#80122441',GradientType=0 );

  min-height: 62px;
  margin-bottom: 10px;
  @media (max-width: 992px) {
    padding: 0;
    min-height: 0px;
    margin-bottom: 15px;
    background: $gray-light !important;
  }
  .logo {
    font-size: 30px;
    color: #FFF;
    display: block;
    line-height: 1;
    b {
      font-size: 35px;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }

  .btn.btn-primary {
    padding: 9px 15px;
    height: 42px;
    background-color: $orangeCTA;
    border-color: $orangeCTA;
    font-size: 16px;
    &:hover {
      background-color: darken($orangeCTA, 5);
      border-color: darken($orangeCTA, 5);
    }
    &.btn-sm {
      padding: 5px 10px;
      height: auto;
    }
    i {
      vertical-align: middle;
      position: static;
      color: #FFF;
      top: 0;
      left: 0;
    }
  }

  .detailed-search-link {
    color: $dark;
    display: block;
    width: 100%;
    /*border-left: 1px solid #fff;*/
    height: 100%;
    line-height: 15px;
    font-size: 80%;
    padding-left: 2px;
    text-align: center;
    @media (max-width: 992px) {
      line-height: 42px;
      font-size: 100%;
    }
  }
  .search-short-up {

  }
}

.scrolled .header {
  /*padding: 10px 0;*/
  background: rgba(236, 236, 238, .9);
  padding: 10px 0;
  @media (max-width: 992px) {
    padding: 0;
  }
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}
.singledatepicker {
  input {
    border: 0px none;
    font-size: 16px;
  }
}
.scrolled .search-fields {
    &__input {
      background-color: $white !important;
    }
    .singledatepicker {
      .input-group-addon {
        border: 0px none !important;
        background-color: $white !important;
      }
      input {
        background-color: $white !important;
      }
    }
  }


.search-fields {
  > div {
    position: relative;
    > i {
      font-size: 22px;
      position: absolute;
      left: 15px;
      top: 10px;
      color: $gray-dark;
    }
  }
  &__input {
    background: $gray-light !important;
    height: 42px;
    padding: 14px 15px 10px 40px;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    color: $dark;
    width: 100%;
    border-radius: 4px;
    @media (max-width: 992px) {
      margin-bottom: 10px;
      background: $white !important;
    }

    &--no-icon {
      padding: 10px 15px;
      > span i {
        position: relative;
        top: 3px;
      }
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 2px 1px $blue-light;
    }
  }
}

@media (max-width: 992px) {
  .singledatepicker {
    margin-bottom: 10px;
    background: $white !important;
    .input-group-addon {
      background: $white !important;
      border: 0 none;
    }
    .form-control {
      background: $white !important;
    }
  }
}

.child-ages {
  margin-top: 10px;
  &__item {
    margin: 0 0 10px 30px;
    input {
      background: #FFF;
    }
  }
}

.mobile-menu {
  text-align: right;
  display: none;
  height: 55px;
  @media (max-width: 992px) {
    display: block;
  }
  .logo {
    float: left;
    font-size: 30px;
    color: $text;
    margin: 0;
    @media (max-width: 992px) {
      margin: 10px 0 5px;
      display: block;
      float: none;
    }
  }
}

.mobile-searchform {
  text-align: right;
  position: relative;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  .mobile-searchform-label {
    float: left;
    font-size: 22px;
    color: $dark;
    margin: 20px 0 0 0;
  }
  .nav-icon span {
    color: $dark;
    height: 100%;
    background: none;
    font-size: 30px;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;

  }
  .nav-icon.open span:nth-child(1) {
    top: 0px;
    color: $gray;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }
}
.header-searchform {
  @media (max-width: 992px) {
    margin: 20px 0 15px;
  }
}

  @media (max-width: 992px) {
    .toggle-searchform {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &.open {
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        display: block !important;
      }
      &.closed {
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        display: none;
      }
    }
  }

.mobile-mapsearch-label {
  font-size: 14px;
  display: block;
  float: left;
  color: $dark;
  margin: 11px 0 0 0;
  padding-right: 20px;
  @media (max-width: 992px) {
    margin: 20px 0 0 0;
    font-size: 18px;
  }
}
.mobile-mapsearch {
  text-align: right;
  position: relative;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  .nav-icon span {
    color: #fff;
  }
}
.mapsearch {
  float: left;
  @media (max-width: 992px) {
    display: none;
    margin-bottom: 15px;
    float: none;
  }
}

.navbar-offers {
  border-radius: 0;
  i {
    font-size: 17px;
  }
}

.phone-number {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 3px;
  color: $text;
  font-weight: bold;
  font-size: 20px;
  margin: 8px 15px 0 10px;
  background: $gray;
}
