.modal-backdrop, .modal-backdrop.in {
  background: #DDD;
  opacity: .90;
}

.modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
}

.modal-body {
  padding: 10px 20px;
}

.modal-header {
  padding: 10px 20px 5px;
  border-color: $gray;
}

.modal-footer {
  padding: 20px 20px;
}

.modal-footer {
  text-align: center;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 750px;
  }
}

