.offer {
  background: $gray-light;
  text-align: center;
  display: block;
  transition: all .3s;
  margin-bottom: 20px;
  position: relative;
  &:hover {
    outline: 2px solid $dark;
    box-shadow: 0 0 30px rgba(0, 0, 0, .35);
    background: #FFF;
    text-decoration: none;
  }
  h2 {
    margin-bottom: 0;
  }
  .image {
    position: relative;
    margin-bottom: 15px;
    .remaining {
      position: absolute;
      top: 12px;
      right: 10px;
      line-height: 30px;
      font-weight: bold;
      padding: 0px 8px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.9);
      .days {
        color: $orangeCTA;
      }
    }
    .meta {
      position: absolute;
      left: 0;
      bottom: 65px;
      width: 100%;
      @media (max-width: 992px) {
        bottom: 100px;
        font-size: 80%;
      }
    }

  }
  .rating {
    float: right;
  }
  .class {
    float: left;
  }
  .stars {
    display: inline-block;
    color: $yellow;
  }
  .meta {
    padding: 50px 15px 10px 15px;
    color: #FFF;
    background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
    background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
    /*
        background: -moz-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
        background: linear-gradient(to bottom, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
        */

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0);
  }
  .action {
    text-transform: uppercase;
    font-weight: bold;
  }
  &.invisible-action {
    .action {
      display: none;
      height: 40px;
      margin-bottom: -40px;
    }
    &:hover {
      z-index: 500;
      margin-bottom: 20px;
      .action {
        display: block;
      }
    }
  }
  .meta-common {
    width: 100%;
    padding: 5px 15px 15px;
    color: $text;
    position: relative;
    min-height: 35px;
    .more-count {
      float: right;
    }
    .rating {
      float: left;
    }
    .stars {
      width: 100%;
      position: absolute;
      left: 0;
      top: 4px;
      i {
        margin-right: 1px;
      }
    }
  }
  .price-tag {
    b {
      color: $text;
      font-size: 30px;
      line-height: 32px;
      span {
        color: $gray-dark;
        display: block;
        font-weight: normal;
        line-height: 1.3;
        font-size: 15px;
      }
    }
  }
  &.large {
    background: none;
    text-align: left;
    margin-bottom: 0;
    .image {
      display: block;
      margin-bottom: 0;
      height: 420px;
      @media (max-width: 992px) {
        height: 100%;
      }
    }
    &:hover {
      outline: none;
      box-shadow: none;
    }
    h1 {
      padding-top: 5px;
      font-size: 100%;
      font-weight: bold;
      line-height: 25px;
      /*width: 70%;*/
      float: left;
      margin: 0;
      @media (max-width: 992px) {
        font-size: 120%;
        line-height: 20px;
      }
      > span {
        display: block;
        margin: 5px 0 0 0;
        font-size: 15px;
        font-weight: normal;
      }
    }
    .price-tag {
      float: right;
      text-align: right;
      b {
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
        color: #FFF;
        span {
          display: inline-block;
          color: #FFF;
          font-size: 13px;
        }
      }
      //   .action {
      //     padding: 16px 10px;
      //     display: inline-block;
      //     margin-left: 15px;
      //   }
    }
  }
}
.list.lined .action {
  width: 30px;
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -7px;
  }
}

.offer-room {
  padding: 10px 0;
  @extend %clearfix;
  &__title {
    background: $blue;
    padding: 10px;
    color: #FFF;
    clear: both;
    margin: 0 0 20px 0;
    white-space: nowrap;
    /*overflow: hidden;
    text-overflow: ellipsis;*/
    span {
      font-weight: bold;
    }
    a {
      color: #FFF;
    }
  }
  &__image {
    width: 160px;
    float: left;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__book {
    // margin-left: 180px;
  }
}

.offer-modal-title {
  padding: 15px 30px;
  margin: -10px -20px 10px;
  background: $gray-light;
  position: relative;
  h1 {
    margin: 0 0 5px 0;
    font-size: 23px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    margin: 0;
  }
  &__total {
    font-size: 23px;
    /*color: $orange;*/
    color: $orangeCTA;
    float: right;
    display: block;
    &.only-offer {
      color: $orange;
    }
  }
  &__detail-btn {
    color: #666;
    cursor: pointer;
    display: block;
  }
}

.offer-modal-subtitle {
  padding-top: 15px;
  margin: 0 0 15px 0;
  font-weight: bold;
}

.main-offers {
  background-color: #fff;
  h2 {
    margin-top: 0;
  }
  .slimScrollDiv {
    @media (max-width: 992px) {
      height: auto !important;
    }
  }
  .main-offers-content {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    height: 420px;
    //overflow-x: hidden;
    //overflow-y: scroll;
    .price--discount  {
      font-size: inherit;
    }
    a.hotel-name {
      font-size: 16px;
    }
    a.package-name {
      font-weight: normal !important;
      font-size: 12px;
    }
    @media (max-width: 992px) {
      height: auto !important;
      .image, .action {
        display: none;
      }
    }
    @media only screen and (device-width: 768px) {
      .image, .action {
        display: block;
      }
    }
    .forward-action {
      text-align: center;
      width: 29px;
      background-color: $gray-background;
      color: $white;
      font-size:14px;
      display: table-cell;
      /*vertical-align: middle;*/
      line-height: 70px;
      z-index: 100;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      cursor: pointer;
      a {
        display: block;
        height: 100%;
        width: 100%;
        &:hover, &:active, &:visited {
          color: $white;
        }
      }
      &:hover {
        background-color: $orangeCTA;
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
    }
  }
  .item {
    padding: 0;
    .percent {
      position: absolute;
      top: 4px;
      left: -3px;
      background-color: $orangeCTA;
      border-radius: 5px;
      color: $white;
      font-weight: bold;
      line-height: 28px;
      width: 28px;
      text-align: center;
      z-index: 1000;
      border: 1px solid $orangeCTA;
      background: -moz-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
      background: linear-gradient(to bottom, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
      @include rotate(-15);
      @media (max-width: 992px) {
        top: 10px;
        left: 10px;
        bottom: inherit;
        right: inherit;
      }
    }
  }
  @media (max-width: 992px) {
    margin-top: 20px;
  }
}
