.btn {
  padding: 8px 15px;
  //height: 42px;
  &.btn-primary {
    background-color: $orangeCTA;
    border-color: $orangeCTA;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:hover {
      background-color: darken($orangeCTA, 10);
      border-color: darken($orangeCTA, 10);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    &.warn {
      background-color: $yellow;
      border-color: $yellow;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:hover {
        background-color: darken($yellow, 10);
        border-color: darken($yellow, 10);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
    }
  }
  &.btn-secondary {
    background-color: $dark;
    border-color: $dark;
    color: #FFF;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:hover {
      color: #FFF;
      background-color: darken($dark, 10);
      border-color: darken($dark, 10);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
  }
  &.btn-loading:not(:required):before {
    content: '';
    display: inline-block;
    font-size: 6px;
    width: 6px;
    height: 6px;
    margin-top: 7px;
    margin-right: 14px;
    float: left;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 1) 1.5em 0 0 0, rgba(255, 255, 255, 1) 1.1em 1.1em 0 0, rgba(255, 255, 255, 1) 0 1.5em 0 0, rgba(255, 255, 255, 1) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(255, 255, 255, 1) 0 -1.5em 0 0, rgba(255, 255, 255, 1) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 1) 1.5em 0 0 0, rgba(255, 255, 255, 1) 1.1em 1.1em 0 0, rgba(255, 255, 255, 1) 0 1.5em 0 0, rgba(255, 255, 255, 1) -1.1em 1.1em 0 0, rgba(255, 255, 255, 1) -1.5em 0 0 0, rgba(255, 255, 255, 1) -1.1em -1.1em 0 0, rgba(255, 255, 255, 1) 0 -1.5em 0 0, rgba(255, 255, 255, 1) 1.1em -1.1em 0 0;
  }
  &.btn-lg.btn-loading:not(:required):before {
    content: '';
    display: inline-block;
    font-size: 7px;
    width: 7px;
    height: 7px;
    margin-top: 8px;
    margin-right: 16px;
    float: left;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 1) 1.5em 0 0 0, rgba(255, 255, 255, 1) 1.1em 1.1em 0 0, rgba(255, 255, 255, 1) 0 1.5em 0 0, rgba(255, 255, 255, 1) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(255, 255, 255, 1) 0 -1.5em 0 0, rgba(255, 255, 255, 1) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 1) 1.5em 0 0 0, rgba(255, 255, 255, 1) 1.1em 1.1em 0 0, rgba(255, 255, 255, 1) 0 1.5em 0 0, rgba(255, 255, 255, 1) -1.1em 1.1em 0 0, rgba(255, 255, 255, 1) -1.5em 0 0 0, rgba(255, 255, 255, 1) -1.1em -1.1em 0 0, rgba(255, 255, 255, 1) 0 -1.5em 0 0, rgba(255, 255, 255, 1) 1.1em -1.1em 0 0;
  }
  &.btn-blue {
    border-color: $blue;
    color: $blue;
  }
  &.btn-sm {
    padding: 5px 12px;
    height: 36px;
  }
  &.btn-xs {
    padding: 3px 4px;
    height: 20px;
  }
}
