@charset "UTF-8";
/***** RÉGI *****/
/*$text: #44444B;
$gray-dark: #666666;
$gray-background: #445567;*/
/***** ÚJ *****/
/*** meta-k színe is átállt az új padlizsán színre ***/
/*$orangeCTA: #ec9539;*/
@-webkit-keyframes message-us {
  0% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  90% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  95% {
    font-size: 110%;
    opacity: 0.5;
    width: 110%;
    margin-left: -10px; }
  100% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; } }

@-moz-keyframes message-us {
  0% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  90% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  95% {
    font-size: 110%;
    opacity: 0.5;
    width: 110%;
    margin-left: -10px; }
  100% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; } }

@-o-keyframes message-us {
  0% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  90% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  95% {
    font-size: 110%;
    opacity: 0.5;
    width: 110%;
    margin-left: -10px; }
  100% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; } }

@keyframes message-us {
  0% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  90% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; }
  95% {
    font-size: 110%;
    opacity: 0.5;
    width: 110%;
    margin-left: -10px; }
  100% {
    font-size: 100%;
    opacity: 1;
    width: 100%;
    margin-left: 0px; } }

.mb-sm {
  margin-bottom: 10px; }

.mb-md {
  margin-bottom: 15px; }

.mb-lg {
  margin-bottom: 20px; }

.mb-xlg {
  margin-bottom: 40px; }

.mt-sm {
  margin-top: 10px; }

.mt-md {
  margin-top: 15px; }

.mt-lg {
  margin-top: 20px; }

.mr-sm {
  margin-right: 10px; }

.mr-md {
  margin-right: 15px; }

.cpt {
  padding-top: 0; }

.cmt {
  margin-top: 0; }

.row.thin {
  margin: 0 -5px; }
  .row.thin [class^='col-'] {
    padding: 0 5px; }

@font-face {
  font-family: 'szallodak';
  src: url("../fonts/szallodak.eot?c5e5xt");
  src: url("../fonts/szallodak.eot?c5e5xt#iefix") format("embedded-opentype"), url("../fonts/szallodak.ttf?c5e5xt") format("truetype"), url("../fonts/szallodak.woff?c5e5xt") format("woff"), url("../fonts/szallodak.svg?c5e5xt#szallodak") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'szallodak' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-adult:before {
  content: "\e900"; }

.icon-aircondition:before {
  content: "\e901"; }

.icon-arrow-right:before {
  content: "\e902"; }

.icon-baby:before {
  content: "\e903"; }

.icon-babyface:before {
  content: "\e904"; }

.icon-beautycard:before {
  content: "\e905"; }

.icon-calendar:before {
  content: "\e906"; }

.icon-car:before {
  content: "\e907"; }

.icon-card:before {
  content: "\e908"; }

.icon-cat:before {
  content: "\e909"; }

.icon-chevron-down:before {
  content: "\e90a"; }

.icon-chevron-left:before {
  content: "\e90b"; }

.icon-chevron-left-2:before {
  content: "\e90c"; }

.icon-chevron-right:before {
  content: "\e90d"; }

.icon-chevron-right-2:before {
  content: "\e90e"; }

.icon-child:before {
  content: "\e90f"; }

.icon-cutlery:before {
  content: "\e910"; }

.icon-dog:before {
  content: "\e911"; }

.icon-door:before {
  content: "\e912"; }

.icon-facebook:before {
  content: "\e913"; }

.icon-family:before {
  content: "\e914"; }

.icon-help:before {
  content: "\e915"; }

.icon-help-2:before {
  content: "\e916"; }

.icon-history:before {
  content: "\e917"; }

.icon-kids-pool:before {
  content: "\e918"; }

.icon-map:before {
  content: "\e919"; }

.icon-minus:before {
  content: "\e91a"; }

.icon-minus-circle:before {
  content: "\e91b"; }

.icon-night:before {
  content: "\e91c"; }

.icon-open-pool:before {
  content: "\e91d"; }

.icon-parking-lot:before {
  content: "\e91e"; }

.icon-person:before {
  content: "\e91f"; }

.icon-phone:before {
  content: "\e920"; }

.icon-plus:before {
  content: "\e921"; }

.icon-plus-circle:before {
  content: "\e922"; }

.icon-reload:before {
  content: "\e923"; }

.icon-search:before {
  content: "\e924"; }

.icon-star:before {
  content: "\e925"; }

.icon-thermal:before {
  content: "\e926"; }

.icon-thumbs-up:before {
  content: "\e927"; }

.icon-user:before {
  content: "\e928"; }

.icon-wellness:before {
  content: "\e929"; }

.icon-wheelchair:before {
  content: "\e92a"; }

.icon-wifi:before {
  content: "\e92b"; }

.erase-date {
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #909095; }
  .erase-date.detail-date-erase {
    top: 38px;
    right: 20px; }

/** Elements, layout partials **/
.winter-border {
  border: 2px solid #fff !important; }

.winter-border-date .input-group {
  border: 2px solid #fff !important; }

.winter-border-date .input-group-addon, .winter-border-date input {
  height: 38px !important; }

@media only screen and (min-width: 995px) {
  .winter-header {
    background-image: url("/img/top_bg.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #124761 !important; } }

.header-info-bar {
  background: #ECECEE;
  text-align: right;
  padding: 4px 0;
  font-size: 12px; }
  .header-info-bar span {
    margin-left: 20px;
    display: inline-block; }
  @media (max-width: 992px) {
    .header-info-bar {
      display: none; } }

.scrolled .header-info-bar {
  display: none; }

.menu {
  text-align: left; }
  .menu nav, .menu a {
    display: inline-block;
    vertical-align: middle; }
    @media (max-width: 992px) {
      .menu nav, .menu a {
        display: block; } }
  .menu a {
    padding: 10px 9px;
    color: #ffffff;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    @media (max-width: 992px) {
      .menu a {
        color: #44475a; } }
    .menu a.bordered {
      border: 1px solid #ff9326;
      border-radius: 3px;
      padding: 5px 10px; }
      @media (max-width: 992px) {
        .menu a.bordered {
          padding: 10px 9px;
          border: 0 none; } }
    .menu a.maldiv {
      background: url("../img/maldiv_menu_bg.png") no-repeat top left;
      color: #000;
      padding-left: 32px;
      font-weight: bold; }
      .menu a.maldiv:hover, .menu a.maldiv:focus, .menu a.maldiv:active {
        background: url("../img/maldiv_menu_bg.png") no-repeat top left;
        padding-left: 32px;
        color: #000;
        font-weight: bold;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none; }
        @media (max-width: 992px) {
          .menu a.maldiv:hover, .menu a.maldiv:focus, .menu a.maldiv:active {
            background: none;
            padding: 10px;
            font-weight: normal;
            color: #44475a; } }
      @media (max-width: 992px) {
        .menu a.maldiv {
          background: none;
          padding: 10px;
          font-weight: normal;
          color: #44475a; } }
    .menu a:focus, .menu a:active {
      color: #ffffff; }
      @media (max-width: 992px) {
        .menu a:focus, .menu a:active {
          color: #44475a; } }
    .menu a:hover {
      background: #ff9326;
      color: #ffffff;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
    .menu a.nopadding {
      padding: 10px 0; }
    .menu a.smallpadding {
      padding: 10px 5px 8px 5px; }
  .menu a.logo_link {
    padding: 0; }
  .menu .menu-additional {
    /*margin-left: -33px;*/ }
    .menu .menu-additional .phone {
      color: #ff9326;
      font-size: 17px;
      padding: 8px 9px; }
      .menu .menu-additional .phone:hover {
        background-color: inherit;
        color: #ffffff; }
    .menu .menu-additional .phone-available {
      font-size: 13px;
      padding: 0px 3px; }
      .menu .menu-additional .phone-available:hover {
        background-color: inherit; }
  @media (max-width: 992px) {
    .menu nav {
      width: 100%; }
    .menu a {
      display: block;
      border-bottom: 1px solid #D9D9D9;
      font-size: 18px; }
      .menu a.logo_link {
        display: none; }
      .menu a:first-child {
        border-top: 1px solid #D9D9D9; }
    .menu a.inline {
      text-align: center;
      border: none;
      font-size: 20px; }
    .menu .map-link {
      display: none; }
    .menu .menu-additional {
      display: inline-block;
      margin-left: 0px; }
      .menu .menu-additional .phone-available {
        font-size: 17px;
        padding: 0px 3px;
        display: inline-grid; }
        .menu .menu-additional .phone-available div {
          float: left; }
        .menu .menu-additional .phone-available:hover {
          background-color: inherit; }
    .menu .menu-additional a {
      float: left;
      padding: 10px 8px;
      font-size: 18px;
      border: 0 none; }
      .menu .menu-additional a.smallpadding {
        padding: 6px 12px 8px 12px; } }

.map-link {
  background: url("../img/worldmap.png") no-repeat top right;
  background-size: contain;
  width: 75px;
  height: 31px;
  display: inline-block;
  padding: 8px 10px;
  margin: 0 0 0 15px;
  border-left: 1px solid #D9D9D9; }

.header-wrapper {
  height: 138px;
  /* z-index: 1070;*/
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  margin-bottom: 30px; }
  @media (max-width: 992px) {
    .header-wrapper {
      height: auto; } }

.scrolled .header-wrapper {
  position: fixed;
  top: 0; }

.header {
  padding: 40px 0;
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  min-height: 62px;
  margin-bottom: 10px; }
  @media (max-width: 992px) {
    .header {
      padding: 0;
      min-height: 0px;
      margin-bottom: 15px;
      background: #ECECEE !important; } }
  .header .logo {
    font-size: 30px;
    color: #FFF;
    display: block;
    line-height: 1; }
    .header .logo b {
      font-size: 35px; }
    @media (max-width: 992px) {
      .header .logo {
        display: none; } }
  .header .btn.btn-primary {
    padding: 9px 15px;
    height: 42px;
    background-color: #ff9326;
    border-color: #ff9326;
    font-size: 16px; }
    .header .btn.btn-primary:hover {
      background-color: #ff860d;
      border-color: #ff860d; }
    .header .btn.btn-primary.btn-sm {
      padding: 5px 10px;
      height: auto; }
    .header .btn.btn-primary i {
      vertical-align: middle;
      position: static;
      color: #FFF;
      top: 0;
      left: 0; }
  .header .detailed-search-link {
    color: #464759;
    display: block;
    width: 100%;
    /*border-left: 1px solid #fff;*/
    height: 100%;
    line-height: 15px;
    font-size: 80%;
    padding-left: 2px;
    text-align: center; }
    @media (max-width: 992px) {
      .header .detailed-search-link {
        line-height: 42px;
        font-size: 100%; } }

.scrolled .header {
  /*padding: 10px 0;*/
  background: rgba(236, 236, 238, 0.9);
  padding: 10px 0;
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out; }
  @media (max-width: 992px) {
    .scrolled .header {
      padding: 0; } }

.singledatepicker input {
  border: 0px none;
  font-size: 16px; }

.scrolled .search-fields__input {
  background-color: #ffffff !important; }

.scrolled .search-fields .singledatepicker .input-group-addon {
  border: 0px none !important;
  background-color: #ffffff !important; }

.scrolled .search-fields .singledatepicker input {
  background-color: #ffffff !important; }

.search-fields > div {
  position: relative; }
  .search-fields > div > i {
    font-size: 22px;
    position: absolute;
    left: 15px;
    top: 10px;
    color: #44475a; }

.search-fields__input {
  background: #ECECEE !important;
  height: 42px;
  padding: 14px 15px 10px 40px;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  color: #464759;
  width: 100%;
  border-radius: 4px; }
  @media (max-width: 992px) {
    .search-fields__input {
      margin-bottom: 10px;
      background: #ffffff !important; } }
  .search-fields__input--no-icon {
    padding: 10px 15px; }
    .search-fields__input--no-icon > span i {
      position: relative;
      top: 3px; }
  .search-fields__input:focus {
    outline: none;
    box-shadow: 0 0 2px 1px #00bae1; }

@media (max-width: 992px) {
  .singledatepicker {
    margin-bottom: 10px;
    background: #ffffff !important; }
    .singledatepicker .input-group-addon {
      background: #ffffff !important;
      border: 0 none; }
    .singledatepicker .form-control, .singledatepicker #newsletter_form .dropdown-toggle, #newsletter_form .singledatepicker .dropdown-toggle {
      background: #ffffff !important; } }

.child-ages {
  margin-top: 10px; }
  .child-ages__item {
    margin: 0 0 10px 30px; }
    .child-ages__item input {
      background: #FFF; }

.mobile-menu {
  text-align: right;
  display: none;
  height: 55px; }
  @media (max-width: 992px) {
    .mobile-menu {
      display: block; } }
  .mobile-menu .logo {
    float: left;
    font-size: 30px;
    color: #44475a;
    margin: 0; }
    @media (max-width: 992px) {
      .mobile-menu .logo {
        margin: 10px 0 5px;
        display: block;
        float: none; } }

.mobile-searchform {
  text-align: right;
  position: relative;
  display: none; }
  @media (max-width: 992px) {
    .mobile-searchform {
      display: block; } }
  .mobile-searchform .mobile-searchform-label {
    float: left;
    font-size: 22px;
    color: #464759;
    margin: 20px 0 0 0; }
  .mobile-searchform .nav-icon span {
    color: #464759;
    height: 100%;
    background: none;
    font-size: 30px;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  .mobile-searchform .nav-icon.open span:nth-child(1) {
    top: 0px;
    color: #D9D9D9;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none; }

@media (max-width: 992px) {
  .header-searchform {
    margin: 20px 0 15px; } }

@media (max-width: 992px) {
  .toggle-searchform {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    .toggle-searchform.open {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      display: block !important; }
    .toggle-searchform.closed {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      display: none; } }

.mobile-mapsearch-label {
  font-size: 14px;
  display: block;
  float: left;
  color: #464759;
  margin: 11px 0 0 0;
  padding-right: 20px; }
  @media (max-width: 992px) {
    .mobile-mapsearch-label {
      margin: 20px 0 0 0;
      font-size: 18px; } }

.mobile-mapsearch {
  text-align: right;
  position: relative;
  display: none; }
  @media (max-width: 992px) {
    .mobile-mapsearch {
      display: block; } }
  .mobile-mapsearch .nav-icon span {
    color: #fff; }

.mapsearch {
  float: left; }
  @media (max-width: 992px) {
    .mapsearch {
      display: none;
      margin-bottom: 15px;
      float: none; } }

.navbar-offers {
  border-radius: 0; }
  .navbar-offers i {
    font-size: 17px; }

.phone-number {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 3px;
  color: #44475a;
  font-weight: bold;
  font-size: 20px;
  margin: 8px 15px 0 10px;
  background: #D9D9D9; }

.featured-tooltip-content {
  width: 420px;
  min-height: 170px;
  background: #fff;
  border: 1px solid black;
  padding: 15px;
  position: relative;
  margin-left: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); }
  .featured-tooltip-content__left {
    width: 185px;
    float: left; }
    .featured-tooltip-content__left img {
      width: 100%;
      height: 140px; }
  .featured-tooltip-content__right {
    padding-left: 10px;
    width: 200px;
    float: right; }
    .featured-tooltip-content__right > h4 {
      font-weight: normal;
      margin-bottom: 0; }
    .featured-tooltip-content__right .price {
      position: absolute;
      bottom: 0;
      white-space: unset;
      padding-right: 10px; }
  .featured-tooltip-content::after, .featured-tooltip-content::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .featured-tooltip-content::after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 10px;
    margin-top: -10px; }
  .featured-tooltip-content::before {
    border-color: transparent;
    border-right-color: #000;
    border-width: 11px;
    margin-top: -11px; }

.footer {
  padding: 0;
  /*margin-top: -170px;*/ }
  .footer .icon-facebook {
    font-size: 30px;
    color: #44475a;
    float: right; }
  .footer .container {
    position: relative; }
  .footer nav {
    margin: 10px 0;
    font-size: 13px;
    text-align: center; }
    .footer nav a {
      display: inline-block;
      padding: 2px 10px;
      border-right: 1px solid #D9D9D9;
      color: #44475a; }
      .footer nav a:last-child {
        border: 0; }
  .footer .secondary {
    background: #44475a;
    padding: 15px 0;
    margin: 0; }
    .footer .secondary nav a {
      border-color: rgba(255, 255, 255, 0.2);
      color: #FFF; }
  .footer .award-logo {
    float: right;
    margin-top: -20px; }
  .footer .copy {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    text-align: center;
    padding: 10px 0; }
    .footer .copy a {
      color: rgba(255, 255, 255, 0.5); }
  .footer .tabs {
    background: #686D88;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    bottom: -15px;
    right: 20px; }
    .footer .tabs a {
      display: inline-block;
      padding: 7px 20px;
      font-size: 16px;
      color: #FFF;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      text-transform: uppercase; }
      .footer .tabs a:last-child {
        border: 0; }
      .footer .tabs a i {
        font-size: 24px;
        margin-right: 10px; }

.offer {
  background: #ECECEE;
  text-align: center;
  display: block;
  transition: all .3s;
  margin-bottom: 20px;
  position: relative; }
  .offer:hover {
    outline: 2px solid #464759;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
    background: #FFF;
    text-decoration: none; }
  .offer h2 {
    margin-bottom: 0; }
  .offer .image {
    position: relative;
    margin-bottom: 15px; }
    .offer .image .remaining {
      position: absolute;
      top: 12px;
      right: 10px;
      line-height: 30px;
      font-weight: bold;
      padding: 0px 8px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.9); }
      .offer .image .remaining .days {
        color: #ff9326; }
    .offer .image .meta {
      position: absolute;
      left: 0;
      bottom: 65px;
      width: 100%; }
      @media (max-width: 992px) {
        .offer .image .meta {
          bottom: 100px;
          font-size: 80%; } }
  .offer .rating {
    float: right; }
  .offer .class {
    float: left; }
  .offer .stars {
    display: inline-block;
    color: #F9B12D; }
  .offer .meta {
    padding: 50px 15px 10px 15px;
    color: #FFF;
    background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
    background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
    background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, #445567 100%);
    /*
        background: -moz-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
        background: linear-gradient(to bottom, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
        */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0); }
  .offer .action {
    text-transform: uppercase;
    font-weight: bold; }
  .offer.invisible-action .action {
    display: none;
    height: 40px;
    margin-bottom: -40px; }
  .offer.invisible-action:hover {
    z-index: 500;
    margin-bottom: 20px; }
    .offer.invisible-action:hover .action {
      display: block; }
  .offer .meta-common {
    width: 100%;
    padding: 5px 15px 15px;
    color: #44475a;
    position: relative;
    min-height: 35px; }
    .offer .meta-common .more-count {
      float: right; }
    .offer .meta-common .rating {
      float: left; }
    .offer .meta-common .stars {
      width: 100%;
      position: absolute;
      left: 0;
      top: 4px; }
      .offer .meta-common .stars i {
        margin-right: 1px; }
  .offer .price-tag b {
    color: #44475a;
    font-size: 30px;
    line-height: 32px; }
    .offer .price-tag b span {
      color: #44475a;
      display: block;
      font-weight: normal;
      line-height: 1.3;
      font-size: 15px; }
  .offer.large {
    background: none;
    text-align: left;
    margin-bottom: 0; }
    .offer.large .image {
      display: block;
      margin-bottom: 0;
      height: 420px; }
      @media (max-width: 992px) {
        .offer.large .image {
          height: 100%; } }
    .offer.large:hover {
      outline: none;
      box-shadow: none; }
    .offer.large h1 {
      padding-top: 5px;
      font-size: 100%;
      font-weight: bold;
      line-height: 25px;
      /*width: 70%;*/
      float: left;
      margin: 0; }
      @media (max-width: 992px) {
        .offer.large h1 {
          font-size: 120%;
          line-height: 20px; } }
      .offer.large h1 > span {
        display: block;
        margin: 5px 0 0 0;
        font-size: 15px;
        font-weight: normal; }
    .offer.large .price-tag {
      float: right;
      text-align: right; }
      .offer.large .price-tag b {
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
        color: #FFF; }
        .offer.large .price-tag b span {
          display: inline-block;
          color: #FFF;
          font-size: 13px; }

.list.lined .action {
  width: 30px; }
  .list.lined .action i {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -7px; }

.offer-room {
  padding: 10px 0; }
  .offer-room__title {
    background: #006C9F;
    padding: 10px;
    color: #FFF;
    clear: both;
    margin: 0 0 20px 0;
    white-space: nowrap;
    /*overflow: hidden;
    text-overflow: ellipsis;*/ }
    .offer-room__title span {
      font-weight: bold; }
    .offer-room__title a {
      color: #FFF; }
  .offer-room__image {
    width: 160px;
    float: left; }
    .offer-room__image img {
      max-width: 100%;
      height: auto; }

.offer-modal-title {
  padding: 15px 30px;
  margin: -10px -20px 10px;
  background: #ECECEE;
  position: relative; }
  .offer-modal-title h1 {
    margin: 0 0 5px 0;
    font-size: 23px; }
  .offer-modal-title h2 {
    font-size: 18px; }
  .offer-modal-title h3 {
    margin: 0; }
  .offer-modal-title__total {
    font-size: 23px;
    /*color: $orange;*/
    color: #ff9326;
    float: right;
    display: block; }
    .offer-modal-title__total.only-offer {
      color: #FC5800; }
  .offer-modal-title__detail-btn {
    color: #666;
    cursor: pointer;
    display: block; }

.offer-modal-subtitle {
  padding-top: 15px;
  margin: 0 0 15px 0;
  font-weight: bold; }

.main-offers {
  background-color: #fff; }
  .main-offers h2 {
    margin-top: 0; }
  @media (max-width: 992px) {
    .main-offers .slimScrollDiv {
      height: auto !important; } }
  .main-offers .main-offers-content {
    border-top: 1px solid #ECECEE;
    border-bottom: 1px solid #ECECEE;
    height: 420px; }
    .main-offers .main-offers-content .price--discount {
      font-size: inherit; }
    .main-offers .main-offers-content a.hotel-name {
      font-size: 16px; }
    .main-offers .main-offers-content a.package-name {
      font-weight: normal !important;
      font-size: 12px; }
    @media (max-width: 992px) {
      .main-offers .main-offers-content {
        height: auto !important; }
        .main-offers .main-offers-content .image, .main-offers .main-offers-content .action {
          display: none; } }
    @media only screen and (device-width: 768px) {
      .main-offers .main-offers-content .image, .main-offers .main-offers-content .action {
        display: block; } }
    .main-offers .main-offers-content .forward-action {
      text-align: center;
      width: 29px;
      background-color: #44475a;
      color: #ffffff;
      font-size: 14px;
      display: table-cell;
      /*vertical-align: middle;*/
      line-height: 70px;
      z-index: 100;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      cursor: pointer; }
      .main-offers .main-offers-content .forward-action a {
        display: block;
        height: 100%;
        width: 100%; }
        .main-offers .main-offers-content .forward-action a:hover, .main-offers .main-offers-content .forward-action a:active, .main-offers .main-offers-content .forward-action a:visited {
          color: #ffffff; }
      .main-offers .main-offers-content .forward-action:hover {
        background-color: #ff9326;
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out; }
  .main-offers .item {
    padding: 0; }
    .main-offers .item .percent {
      position: absolute;
      top: 4px;
      left: -3px;
      background-color: #ff9326;
      border-radius: 5px;
      color: #ffffff;
      font-weight: bold;
      line-height: 28px;
      width: 28px;
      text-align: center;
      z-index: 1000;
      border: 1px solid #ff9326;
      background: -moz-linear-gradient(top, #ff9326 0%, #f26826 100%);
      background: -webkit-linear-gradient(top, #ff9326 0%, #f26826 100%);
      background: linear-gradient(to bottom, #ff9326 0%, #f26826 100%);
      -moz-transform: rotate(-15deg);
      -o-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg); }
      @media (max-width: 992px) {
        .main-offers .item .percent {
          top: 10px;
          left: 10px;
          bottom: inherit;
          right: inherit; } }
  @media (max-width: 992px) {
    .main-offers {
      margin-top: 20px; } }

.city {
  position: relative;
  display: block;
  height: 175px; }
  .city .meta {
    position: absolute;
    padding: 40px 15px 10px 15px;
    color: #FFF;
    bottom: 0;
    left: 0;
    width: 100%;
    background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
    background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
    background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, #445567 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0044475a', endColorstr='#44475a', GradientType=0);
    transition: all .3s; }
    .city .meta h4 {
      margin: 0;
      float: left; }
    .city .meta .meta-description {
      clear: both; }
    .city .meta .more-count {
      float: right; }
  .city:hover .meta {
    padding-top: 10px; }

.tabs a {
  display: inline-block;
  font-size: 20px;
  padding: 12px 20px;
  color: #44475a; }
  .tabs a.active {
    font-weight: bold;
    border-bottom: 2px solid #00A0C8; }

.tab-contents {
  border: 1px solid #ECECEE; }

h1, h2, h3, h4 {
  font-weight: bold; }

h1 {
  font-size: 28px;
  margin: 0 0 20px 0; }
  @media (max-width: 1100px) {
    h1 {
      font-size: 24px; } }

h2 {
  font-size: 22px; }

h3 {
  font-size: 20px; }

a {
  color: #464759; }
  a:hover, a:focus, a:active {
    text-decoration: none;
    color: #464759; }

.list .item {
  border-bottom: 1px solid #ECECEE;
  display: table;
  width: 100%;
  position: relative;
  height: 84px; }
  .list .item > div {
    position: relative;
    display: table-cell;
    padding: 6px 8px 5px;
    vertical-align: top; }
    .list .item > div h3, .list .item > div h4 {
      margin-top: 0; }
  .list .item .image {
    padding-right: 10px;
    padding-left: 0; }
  .list .item table {
    width: 100%; }
  .list .item .content {
    width: 100%; }
  .list .item .price {
    display: block;
    text-align: right;
    font-size: 16px; }

.list-lead .text-content a {
  color: #ff9326; }

.list-description {
  position: relative;
  background: #000; }
  .list-description__image {
    background-position: center center;
    background-size: cover;
    position: absolute;
    opacity: .5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .list-description__text {
    padding: 50px;
    height: 300px;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 300;
    text-align: center;
    color: #FFF;
    margin: auto; }
    .list-description__text_normal {
      text-align: justify; }
    .list-description__text h1 {
      margin: 0 0 5px 0; }

.list--scrollable {
  overflow: hidden; }

.list--lined .item {
  padding: 0; }

.tab.list .item > div {
  padding: 15px; }
  .tab.list .item > div.image {
    padding-right: 0;
    padding-left: 15px; }

.list-header {
  margin: 20px 0; }
  @media (max-width: 992px) {
    .list-header {
      text-align: center; } }
  .list-header:before, .list-header:after {
    clear: both;
    display: table;
    content: ''; }
  .list-header .pagination {
    float: left;
    margin: 0; }
    @media (max-width: 992px) {
      .list-header .pagination {
        float: none;
        margin: 0 auto 20px; } }
    .list-header .pagination > li:first-child a, .list-header .pagination > li:last-child a, .list-header .pagination > li:first-child span, .list-header .pagination > li:last-child span {
      border-radius: 0; }
    .list-header .pagination > li > a {
      padding: 6px 10px; }
      @media (max-width: 992px) {
        .list-header .pagination > li > a {
          padding: 7px; } }
    .list-header .pagination a:hover {
      background: rgba(236, 236, 238, 0.5); }
  .list-header .pagination-arrow a {
    background: rgba(236, 236, 238, 0.5); }

.list-lead p {
  text-align: justify; }

@media (max-width: 992px) {
  .list-lead {
    display: none; } }

.order-container {
  float: right;
  border: 1px solid #006C9F;
  color: #006C9F;
  border-radius: 2px;
  line-height: 31px;
  padding-top: 2px; }
  .order-container--icon-left {
    margin: 0 10px; }
  .order-container--icon-right {
    margin: 2px 3px 0; }
  .order-container--select {
    width: 210px !important;
    border: 0 none;
    background: none; }
  @media (max-width: 992px) {
    .order-container {
      float: none;
      display: inline-block;
      margin: 0 auto; } }

.map .map-header {
  background: #ECECEE;
  padding: 10px 0;
  color: #464759; }
  .map .map-header b, .map .map-header .form-control, .map .map-header #newsletter_form .dropdown-toggle, #newsletter_form .map .map-header .dropdown-toggle {
    margin-right: 10px; }
    @media (max-width: 992px) {
      .map .map-header b, .map .map-header .form-control, .map .map-header #newsletter_form .dropdown-toggle, #newsletter_form .map .map-header .dropdown-toggle {
        margin-bottom: 10px; } }
  @media (max-width: 992px) {
    .map .map-header {
      padding: 0; } }

.map .form-control, .map #newsletter_form .dropdown-toggle, #newsletter_form .map .dropdown-toggle {
  background: #FFF;
  border-color: #FFF;
  max-width: 210px; }
  @media (max-width: 992px) {
    .map .form-control, .map #newsletter_form .dropdown-toggle, #newsletter_form .map .dropdown-toggle {
      max-width: 100%; } }

.map img {
  width: 100%;
  height: auto; }

.quote {
  background: #ECECEE;
  min-height: 200px;
  font-size: 14px;
  position: relative; }
  .quote .content {
    padding: 15px; }
  .quote .author {
    display: block;
    margin-top: 5px;
    color: #44475a;
    text-align: right; }
  .quote .info {
    border-top: 1px solid #D9D9D9;
    padding: 15px; }
    .quote .info img {
      float: left;
      max-width: 70px;
      max-height: 50px; }
    .quote .info > div {
      margin-left: 80px;
      min-height: 50px; }
    .quote .info h4 {
      margin: 0; }
  .quote:after {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    bottom: -20px;
    width: 20px;
    height: 20px;
    border: 10px solid transparent;
    border-left: 10px solid #ECECEE;
    border-top: 10px solid #ECECEE; }
  .quote .badge {
    background: #ff9326;
    color: #FFF; }

.detail-page .quote {
  min-height: auto;
  margin-bottom: 30px; }
  .detail-page .quote .just4lines {
    margin-left: 50px; }

.btn {
  padding: 8px 15px; }
  .btn.btn-primary {
    background-color: #ff9326;
    border-color: #ff9326;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .btn.btn-primary:hover {
      background-color: #f27a00;
      border-color: #f27a00;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
    .btn.btn-primary.warn {
      background-color: #F9B12D;
      border-color: #F9B12D;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
      .btn.btn-primary.warn:hover {
        background-color: #ec9b07;
        border-color: #ec9b07;
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out; }
  .btn.btn-secondary {
    background-color: #464759;
    border-color: #464759;
    color: #FFF;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .btn.btn-secondary:hover {
      color: #FFF;
      background-color: #30303c;
      border-color: #30303c;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
  .btn.btn-loading:not(:required):before {
    content: '';
    display: inline-block;
    font-size: 6px;
    width: 6px;
    height: 6px;
    margin-top: 7px;
    margin-right: 14px;
    float: left;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: white 1.5em 0 0 0, white 1.1em 1.1em 0 0, white 0 1.5em 0 0, white -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, white 0 -1.5em 0 0, white 1.1em -1.1em 0 0;
    box-shadow: white 1.5em 0 0 0, white 1.1em 1.1em 0 0, white 0 1.5em 0 0, white -1.1em 1.1em 0 0, white -1.5em 0 0 0, white -1.1em -1.1em 0 0, white 0 -1.5em 0 0, white 1.1em -1.1em 0 0; }
  .btn.btn-lg.btn-loading:not(:required):before {
    content: '';
    display: inline-block;
    font-size: 7px;
    width: 7px;
    height: 7px;
    margin-top: 8px;
    margin-right: 16px;
    float: left;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: white 1.5em 0 0 0, white 1.1em 1.1em 0 0, white 0 1.5em 0 0, white -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, white 0 -1.5em 0 0, white 1.1em -1.1em 0 0;
    box-shadow: white 1.5em 0 0 0, white 1.1em 1.1em 0 0, white 0 1.5em 0 0, white -1.1em 1.1em 0 0, white -1.5em 0 0 0, white -1.1em -1.1em 0 0, white 0 -1.5em 0 0, white 1.1em -1.1em 0 0; }
  .btn.btn-blue {
    border-color: #006C9F;
    color: #006C9F; }
  .btn.btn-sm {
    padding: 5px 12px;
    height: 36px; }
  .btn.btn-xs {
    padding: 3px 4px;
    height: 20px; }

.form-control, #newsletter_form .dropdown-toggle {
  background-color: #D9D9D9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  padding: 10px 15px;
  height: 43px; }
  .form-control:focus, #newsletter_form .dropdown-toggle:focus {
    border-color: #FC5800;
    box-shadow: 0 0 0 3px #D9D9D9;
    -webkit-box-shadow: 0 0 0 3px #D9D9D9;
    background: #FFF; }
  .form-control.form-highlight, #newsletter_form .form-highlight.dropdown-toggle {
    border: 1px solid #006C9F;
    background: #FFF;
    color: #006C9F;
    padding: 0 15px; }

textarea.form-control, #newsletter_form textarea.dropdown-toggle {
  height: auto; }

.nav-icon {
  width: 30px;
  height: 24px;
  position: relative;
  margin: 18px 0 12px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none; }
  @media (max-width: 992px) {
    .nav-icon {
      display: inline-block; } }

.search-icon {
  font-size: 30px;
  margin: 14px 0 0;
  display: inline-block; }

.nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #44475a;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }
  .nav-icon span.white {
    background: #fff; }

.nav-icon span:nth-child(1) {
  top: 0px; }

.nav-icon span:nth-child(2) {
  top: 10px; }

.nav-icon span:nth-child(3) {
  top: 20px; }

.nav-icon.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

.nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -50px; }

.nav-icon.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg); }

.slick-arrow-template, .slider .arrow-left, .slider .arrow-right, .list-body .arrow-left, .list-body .arrow-right, .detail-page .arrow-left, .detail-page .arrow-right {
  position: absolute;
  margin: 0;
  background: rgba(255, 255, 255, 0.85);
  font-size: 17px;
  text-align: center;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  line-height: 35px;
  z-index: 200;
  cursor: pointer; }

.slick-arrow-shadow, .slider .arrow-left, .slider .arrow-right, .slider-cities.slider .arrow-left.arrow-right, .slider-cities.slider .arrow-right, .slider-cities.slider .arrow-left, .detail-image-slider.slider .arrow-right, .detail-image-slider.slider .arrow-left, .list-body .arrow-left.slick-next, .list-body .arrow-right.slick-next, .list-body .arrow-left.slick-prev, .list-body .arrow-right.slick-prev, .detail-page .arrow-left.slick-next, .detail-page .arrow-right.slick-next, .detail-page .arrow-left.slick-prev, .detail-page .arrow-right.slick-prev {
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.2); }

.slider .arrow-left, .slider .arrow-right {
  top: 190px;
  /*@media (max-width: 992px) {
      top: 10%;
    }*/ }
  @media (max-width: 992px) {
    .slider .arrow-left, .slider .arrow-right {
      display: none !important; } }

.slider .arrow-left {
  left: 10px;
  padding-right: 3px; }

.slider .arrow-right {
  right: 10px;
  padding-left: 3px; }

.slick-dots {
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -35px; }
  .slick-dots li {
    display: inline-block;
    padding: 7px; }
    .slick-dots li.slick-active button {
      background: #00A0C8; }
    .slick-dots li button {
      overflow: hidden;
      border: 0;
      padding: 0;
      background: rgba(70, 71, 89, 0.5);
      border-radius: 8px;
      text-indent: 1000px;
      display: block;
      width: 8px;
      height: 8px; }

.slider-cities.slider .arrow-left, .slider-cities.slider .arrow-right {
  position: initial;
  overflow: hidden; }
  .slider-cities.slider .arrow-left.arrow-right, .slider-cities.slider .arrow-right.arrow-right {
    position: absolute;
    right: -5px;
    top: 70px; }
  .slider-cities.slider .arrow-left.arrow-left, .slider-cities.slider .arrow-right.arrow-left {
    position: absolute;
    left: -5px;
    top: 70px; }
  @media (max-width: 992px) {
    .slider-cities.slider .arrow-left, .slider-cities.slider .arrow-right {
      display: none !important; } }

.detail-image-slider.slider .arrow-left, .detail-image-slider.slider .detail-image-slider.slider .arrow-right {
  position: initial; }

.detail-image-slider.slider .arrow-right {
  position: absolute;
  right: -15px;
  top: 15px; }

.detail-image-slider.slider .arrow-left {
  position: absolute;
  left: -15px;
  top: 15px; }

.list-body .arrow-left, .list-body .arrow-right {
  border: none; }
  .list-body .arrow-left.slick-next, .list-body .arrow-right.slick-next {
    right: -20px;
    bottom: 55px; }
  .list-body .arrow-left.slick-prev, .list-body .arrow-right.slick-prev {
    left: -20px;
    bottom: 55px; }

.detail-page .arrow-left, .detail-page .arrow-right {
  border: none; }
  @media (max-width: 992px) {
    .detail-page .arrow-left, .detail-page .arrow-right {
      display: none !important; } }
  .detail-page .arrow-left.slick-next, .detail-page .arrow-right.slick-next {
    right: -5px;
    top: 175px; }
  .detail-page .arrow-left.slick-prev, .detail-page .arrow-right.slick-prev {
    left: -5px;
    top: 175px; }

.slick-arrow {
  display: none; }

.slider-voucher {
  visibility: hidden;
  height: 300px; }

.slider-home {
  overflow: hidden; }
  .slider-home .meta {
    padding: 30px 15px 10px 15px !important; }
  .slider-home .percent {
    position: absolute;
    bottom: 47px;
    right: 30px;
    background-color: #ff9326;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    line-height: 43px;
    width: 43px;
    text-align: center;
    z-index: 1000;
    border: 1px solid #ff9326;
    background: -moz-linear-gradient(top, #ff9326 0%, #f26826 100%);
    background: -webkit-linear-gradient(top, #ff9326 0%, #f26826 100%);
    background: linear-gradient(to bottom, #ff9326 0%, #f26826 100%);
    -moz-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg); }
    @media (max-width: 992px) {
      .slider-home .percent {
        top: 12px;
        left: 10px;
        bottom: inherit;
        right: inherit; } }
  .slider-home .meta2 {
    color: #FFF;
    padding: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
    background-color: rgba(68, 71, 90, 0.9); }
    @media (max-width: 992px) {
      .slider-home .meta2 {
        height: 100px;
        font-size: 16px;
        line-height: 17px; } }
  .slider-home .oldprice {
    text-decoration: line-through; }
  .slider-home .currentprice {
    color: #ff9326; }
    .slider-home .currentprice.bigger {
      font-size: 120%; }
  .slider-home .slider-title .small {
    color: #ffffff; }
  @media (max-width: 992px) {
    .slider-home--image {
      max-height: 300px; } }
  @media only screen and (device-width: 768px) {
    .slider-home--image {
      max-height: inherit; } }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .slider-home--image {
      max-height: inherit; } }

.slick_on_mobile_wrapper {
  position: absolute;
  background: #0000ff;
  width: 100%;
  height: 100%; }
  .slick_on_mobile_wrapper .circle {
    width: 100px;
    height: 100px;
    background: red;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    position: relative;
    left: 50%;
    top: 50%; }

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #006C9F; }

.range_inputs .btn-sm {
  height: 30px; }

@media (max-width: 992px) {
  .daterangepicker_input {
    display: none; } }

@media (max-width: 992px) {
  .calendar.right {
    border-top: 1px solid #D9D9D9; } }

@media (max-width: 992px) {
  .calendar-table .table-condensed > tbody > tr > td,
  .calendar-table .table-condensed > tbody > tr > th,
  .calendar-table .table-condensed > tfoot > tr > td,
  .calendar-table .table-condensed > tfoot > tr > th,
  .calendar-table .table-condensed > thead > tr > td,
  .calendar-table .table-condensed > thead > tr > th {
    padding: 2px 5px; } }

.daterangepicker.dropdown-menu {
  z-index: 2060; }

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #DDDDDD;
  /* font-style: italic; */
  text-align: center; }

.datepicker .datepicker-days .day {
  width: 29px;
  border-radius: 0; }
  .datepicker .datepicker-days .day.active {
    background: none;
    text-shadow: none;
    background-repeat: no-repeat;
    color: #44475a; }
    .datepicker .datepicker-days .day.active:hover {
      background: #eeeeee; }

.datepicker .datepicker-days .selected-day {
  background-color: #ffdebd; }

.datepicker .datepicker-days .end-day {
  background-image: url("/img/datepicker_end.png") !important;
  background-repeat: no-repeat; }
  .datepicker .datepicker-days .end-day .available-day {
    background-color: #e5ffe6 !important; }

.datepicker .datepicker-days .start-day {
  background-image: url("/img/datepicker_start.png") !important;
  background-repeat: no-repeat; }
  .datepicker .datepicker-days .start-day .available-day {
    background-color: #e5ffe6 !important; }

.datepicker .datepicker-days .available-day {
  background-color: #e5ffe6; }

.szallodak-datepicker {
  font-weight: bold; }
  .szallodak-datepicker i.icon-calendar {
    font-weight: bold; }
  .szallodak-datepicker input {
    padding-right: 0;
    padding-left: 0;
    text-align: center; }
  .szallodak-datepicker span.erase-date {
    right: 7px;
    top: 14px;
    cursor: pointer; }

.header-searchform .szallodak-datepicker .input-group-addon {
  padding-right: 0;
  padding-left: 8px;
  height: 42px; }
  .header-searchform .szallodak-datepicker .input-group-addon.right-addon {
    padding-left: 20px; }

.header-searchform .szallodak-datepicker input {
  height: 42px; }

.reservation-modal-body .szallodak-datepicker .input-group-addon.left-addon {
  padding-right: 0; }

.reservation-modal-body .szallodak-datepicker .input-group-addon.right-addon {
  display: none; }

.reservation-modal-body .szallodak-datepicker span.erase-date {
  top: 12px; }

.detail-booking .szallodak-datepicker .input-group-addon {
  border: 0px none; }
  .detail-booking .szallodak-datepicker .input-group-addon.left-addon {
    display: none; }
  .detail-booking .szallodak-datepicker .input-group-addon.right-addon {
    background-color: #ffffff; }

.detail-booking .szallodak-datepicker input {
  border-right: 0; }

.detail-booking .szallodak-datepicker span.erase-date {
  top: 9px; }

.filter-toggle {
  font-size: 20px;
  color: #FFF;
  background: #ff9326;
  border-radius: 3px;
  text-align: left;
  padding: 3px 15px;
  display: none;
  margin: -15px 0 30px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2); }
  .filter-toggle i {
    line-height: 35px; }

.filter-toggle-icon {
  float: right; }
  .filter-toggle-icon img {
    height: 22px; }

@media (max-width: 992px) {
  .filter-toggle {
    display: block; } }

.filters {
  padding-top: 20px;
  position: relative;
  z-index: 15; }

@media (max-width: 992px) {
  .filters {
    background: #f1f1f3;
    position: absolute;
    display: table;
    padding: 30px 20px;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: 999;
    transform: translate(0, -1250px);
    -webkit-transform: translate(0px, -1250px);
    transition: .5s ease-in-out;
    border-bottom: 2px solid #ff9326; }
    .filters.open {
      transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      box-shadow: 5px 0 20px rgba(0, 0, 0, 0.2); } }

.filter-block {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ECECEE; }
  .filter-block .icon-minus-circle,
  .filter-block .icon-plus-circle {
    color: #006C9F;
    margin: 2px 5px 0 0; }
  .filter-block .icon-minus-circle {
    display: none; }
  .filter-block .icon-plus-circle {
    display: inline-block; }
  .filter-block .filter-content {
    display: none; }
  .filter-block.open .filter-content {
    display: block; }
  .filter-block.open .icon-minus-circle {
    display: inline-block; }
  .filter-block.open .icon-plus-circle {
    display: none; }
  .filter-block.desktop-open .filter-content {
    display: block; }
  .filter-block.desktop-open.open .icon-minus-circle {
    display: inline-block; }
  .filter-block.desktop-open.open .icon-plus-circle {
    display: none; }
  @media (max-width: 992px) {
    .filter-block.desktop-open .filter-content {
      display: none; }
    .filter-block.desktop-open .icon-minus-circle {
      display: none; }
    .filter-block.desktop-open .icon-plus-circle {
      display: inline-block; } }

.filter-content {
  padding: 10px 0;
  font-size: 14px; }

.filter-title {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none; }
  .filter-title h3 {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 0 0;
    line-height: 20px;
    vertical-align: top; }
  .filter-title > a {
    float: right;
    display: block;
    color: #44475a;
    font-size: 15px; }
  .filter-title .badge {
    padding: 5px 7px 3px;
    line-height: 12px;
    background: #ff9326; }

.filter-input span, .filter-input input {
  width: 50%;
  display: inline-block;
  margin-bottom: 5px; }

.filter-input input {
  padding-top: 5px;
  padding-bottom: 5px; }

.filter-group-title {
  border-top: 1px solid #ECECEE;
  font-weight: bold;
  font-size: 13px;
  padding: 5px 0;
  display: block;
  text-transform: uppercase;
  margin: 15px 0 0 0; }
  .filter-group-title:first-child {
    border: 0;
    margin-top: 0; }

.icheckbox_flat, .iradio_flat {
  vertical-align: top;
  margin-right: 12px; }

.filter-radio .btn {
  font-size: 13px; }

.filter-label {
  font-size: 14px;
  font-weight: bold;
  display: block;
  width: 100%;
  border-bottom: 1px solid #f1f1f3;
  padding: 10px 0 0 10px; }

.filter-radio, .filter-checkbox {
  margin-bottom: 5px;
  display: block; }
  .filter-radio .btn, .filter-checkbox .btn {
    margin-right: 5px;
    background: #ECECEE;
    border-color: #ECECEE; }
    .filter-radio .btn:focus, .filter-checkbox .btn:focus {
      outline: none; }
    .filter-radio .btn.checked, .filter-checkbox .btn.checked {
      background: #00A0C8;
      color: #FFF; }

.filter-checkbox {
  display: inline-block; }
  .filter-checkbox i {
    vertical-align: baseline;
    font-size: 15px;
    color: #00A0C8; }

.filter-slider-text {
  display: block;
  font-size: 13px;
  text-align: center; }

.list-order {
  float: right;
  width: 180px; }
  .list-order select {
    width: 100%; }

rzslider .rz-pointer {
  background-color: #006C9F;
  width: 10px;
  height: 24px;
  top: -10px;
  border-radius: 0; }
  rzslider .rz-pointer:after {
    display: none; }

rzslider .rz-bar.rz-selection {
  background: #006C9F; }

.item {
  border-bottom: 1px solid #ECECEE;
  padding: 20px 0; }
  .item__info {
    float: right; }
    @media (max-width: 992px) {
      .item__info {
        float: none;
        display: block;
        text-align: center; } }
    .item__info table {
      float: right; }
      @media (max-width: 992px) {
        .item__info table {
          float: none;
          margin: 5px auto; } }
  .item__name {
    margin: 0 0 15px 0;
    font-size: 24px;
    line-height: 1.1;
    font-weight: bold;
    float: left; }
    @media (max-width: 992px) {
      .item__name {
        margin-top: 10px;
        float: none;
        display: block;
        text-align: center; } }
  .item--highlight {
    /*background: transparentize($highlight, .95);
    border-left: 3px solid $orange;*/
    background: rgba(0, 160, 200, 0.05);
    border: 2px solid #FC5800;
    margin-bottom: 15px;
    padding: 10px; }
  .item__classification {
    position: relative;
    top: -10px;
    /*right: -7px;*/
    white-space: nowrap; }
    .item__classification .icon-star {
      color: #F9B12D;
      font-size: 17px;
      margin-right: 2px; }
  .item__image {
    float: left;
    position: relative; }
    @media (max-width: 992px) {
      .item__image {
        float: none;
        position: relative;
        display: inline-block;
        text-align: center; } }
    .item__image img {
      max-width: 100%;
      height: auto; }
  @media (max-width: 992px) {
    .item__imagecontainer {
      text-align: center; } }
  .item__highlight-badge {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 150px;
    height: 150px; }
    .item__highlight-badge span {
      padding: 5px 0;
      background: #FC5800;
      font-size: 13px;
      color: #FFF;
      display: block;
      transform-origin: center;
      transform: rotate(-45deg);
      text-align: center;
      margin-left: -35px;
      margin-top: 35px;
      width: 170px; }
  .item__location-link {
    position: absolute;
    display: inline-block;
    padding: 7px 10px 4px 10px;
    color: #FFF;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    font-size: 13px;
    right: 10px;
    bottom: 10px; }
    .item__location-link i {
      position: relative;
      top: -1px; }
  .item__content {
    margin-left: 320px;
    position: relative; }
    @media (max-width: 992px) {
      .item__content {
        margin-left: 0;
        float: none; } }
  .item__info span {
    display: inline-block;
    margin-right: 10px; }
    .item__info span i {
      margin-right: 3px; }
    .item__info span .badge {
      background: #ff9326;
      color: #FFF; }
  .item__features {
    margin: 10px 0 18px; }
    .item__features i {
      font-size: 19px;
      margin-right: 5px; }
  .item__morepackages {
    margin: 10px 0 0px; }

.list-offers {
  margin-bottom: 20px; }
  .list-offers__item {
    margin: 0 1px 0 0;
    background: #ECECEE;
    text-align: center;
    height: 140px;
    padding: 15px; }
    .list-offers__item a {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .list-offers__item a, .list-offers__item span, .list-offers__item b {
      display: block; }
  .list-offers__action {
    text-transform: uppercase;
    margin-top: 15px; }
  .list-offers__price-tag {
    font-weight: bold;
    display: block; }
    .list-offers__price-tag span {
      display: inline-block;
      position: relative; }

.price {
  /*font-weight: bold;*/
  display: inline-block; }
  .price--discount {
    opacity: .8;
    margin-right: 10px;
    font-size: 14px;
    font-weight: normal;
    position: relative; }
    .price--discount:before {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 48%;
      background: #FC5800;
      transform: rotate(-12deg);
      transform-origin: center center; }
  .price--discount--label {
    opacity: .8; }

.no-results-info {
  background: #006C9F;
  color: #ffffff;
  padding: 20px;
  font-size: 18px; }
  .no-results-info--icon {
    line-height: 40px;
    font-size: 38px;
    margin: 5px 15px 0 5px; }

.action {
  background: #ff9326;
  color: #FFF;
  padding: 4px 7px 1px 7px;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  transition-duration: .3s;
  font-size: 13px;
  border: 0;
  cursor: pointer; }
  .action:hover, .action:active, .action:focus {
    background: #f27a00;
    color: #FFF; }
  .action.blue {
    background: #006C9F; }
    .action.blue:hover, .action.blue:active, .action.blue:focus {
      background: #00496c;
      color: #FFF; }
  .action--no-bg {
    background: none;
    color: #ff9326;
    margin-top: 10px; }
  .action.warn {
    background: #F9B12D; }
    .action.warn:hover, .action.warn:active, .action.warn:focus {
      background: #ec9b07;
      color: #FFF; }

.item__content .action {
  padding: 8px 16px 6px 16px;
  font-size: 14px;
  font-weight: normal;
  display: inline-block;
  margin-top: 10px; }

.slick-holder {
  height: 140px;
  overflow: hidden; }

.pagination > li > a, .pagination > li > span {
  border: 0;
  border-right: 1px solid #ECECEE;
  margin-left: 0;
  cursor: pointer; }

.pagination > li:last-of-type > a {
  border-right: none; }

.pagination > li:last-of-type > span {
  border-right: none; }

.pagination-counter {
  float: left;
  font-size: 12px;
  line-height: 33px;
  margin-right: 20px;
  padding: 0 15px;
  color: #44475a; }
  @media (max-width: 992px) {
    .pagination-counter {
      display: block;
      margin: 10px auto;
      float: none; } }

.order-icon {
  float: right; }

.detail-header {
  border-bottom: 1px solid #ECECEE;
  padding-bottom: 5px;
  margin-bottom: 10px; }
  .detail-header h1 {
    margin: 5px 0;
    font-size: 25px; }
    .detail-header h1 .icon-star {
      white-space: nowrap;
      font-size: 18px;
      color: #f3d34c; }
  .detail-header h2 {
    font-size: 18px;
    margin: 7px 0; }

.detail-image {
  margin-bottom: 20px;
  position: relative; }

.tab-pane .percent {
  position: absolute;
  top: -4px;
  left: 10px;
  background-color: #ff9326;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  line-height: 43px;
  width: 43px;
  text-align: center;
  z-index: 1000;
  border: 1px solid #ff9326;
  background: -moz-linear-gradient(top, #ff9326 0%, #f26826 100%);
  background: -webkit-linear-gradient(top, #ff9326 0%, #f26826 100%);
  background: linear-gradient(to bottom, #ff9326 0%, #f26826 100%);
  -moz-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg); }
  @media (max-width: 992px) {
    .tab-pane .percent {
      top: -6px;
      line-height: 43px;
      width: 43px;
      font-size: 16px;
      left: 0px;
      bottom: inherit;
      right: inherit; } }

.detail-image-slider {
  display: none;
  margin-bottom: 20px; }
  .detail-image-slider .slick-slide {
    padding: 0 5px; }
    .detail-image-slider .slick-slide img {
      max-width: 100%;
      height: auto; }

.found-packages {
  margin: 0 10px;
  padding-right: 0; }
  .found-packages li {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0; }
    .found-packages li:last-of-type {
      margin-bottom: 10px; }
  .found-packages .btn-sm {
    height: 26px; }
  .found-packages span.package-name {
    display: table-cell;
    vertical-align: middle;
    width: 600px;
    padding: 5px 0; }
    .found-packages span.package-name .package-price {
      font-weight: bold;
      transition: all .25s ease-in-out;
      float: right;
      margin-right: 10px; }
  .found-packages .forward-action {
    text-align: center;
    width: 29px;
    background-color: #44475a;
    color: #ffffff;
    font-size: 14px;
    display: table-cell;
    height: 43px;
    vertical-align: middle;
    z-index: 100;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    cursor: pointer; }
    @media (max-width: 992px) {
      .found-packages .forward-action {
        width: 15%; } }
    .found-packages .forward-action a {
      display: inline;
      height: 100%;
      width: 100%; }
      .found-packages .forward-action a:hover, .found-packages .forward-action a:active, .found-packages .forward-action a:visited {
        color: #ffffff; }
    .found-packages .forward-action:hover {
      background-color: #ff9326;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }

.detail-offer {
  border-bottom: 1px solid #ECECEE;
  display: table;
  width: 100%; }
  .detail-offer .image, .detail-offer .description, .detail-offer .price-wrapper {
    display: table-cell;
    position: relative;
    vertical-align: top;
    padding: 15px;
    white-space: nowrap; }
    @media (max-width: 992px) {
      .detail-offer .image, .detail-offer .description, .detail-offer .price-wrapper {
        white-space: normal; } }
    .detail-offer .image .zoom-icon, .detail-offer .description .zoom-icon, .detail-offer .price-wrapper .zoom-icon {
      display: none; }
      @media (max-width: 992px) {
        .detail-offer .image .zoom-icon, .detail-offer .description .zoom-icon, .detail-offer .price-wrapper .zoom-icon {
          display: inline; } }
    .detail-offer .image .percent, .detail-offer .description .percent, .detail-offer .price-wrapper .percent {
      top: 4px;
      right: -3px;
      position: absolute;
      background-color: #ff9326;
      border-radius: 5px;
      color: #ffffff;
      font-weight: bold;
      line-height: 28px;
      width: 28px;
      text-align: center;
      z-index: 1000;
      border: 1px solid #ff9326;
      background: -moz-linear-gradient(top, #ff9326 0%, #f26826 100%);
      background: -webkit-linear-gradient(top, #ff9326 0%, #f26826 100%);
      background: linear-gradient(to bottom, #ff9326 0%, #f26826 100%);
      -moz-transform: rotate(-15deg);
      -o-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg); }
      @media (max-width: 992px) {
        .detail-offer .image .percent, .detail-offer .description .percent, .detail-offer .price-wrapper .percent {
          top: 10px;
          left: 10px;
          bottom: inherit;
          right: inherit; } }
  .detail-offer .image {
    width: 200px;
    padding-left: 0;
    height: 153px;
    overflow: hidden; }
    .detail-offer .image img {
      max-width: 100%; }
  .detail-offer .image_label {
    padding: 4px;
    margin: 0px;
    position: relative; }
    .detail-offer .image_label .zoom-button {
      position: absolute;
      color: #ffffff;
      z-index: 10;
      opacity: 0.85;
      text-align: center; }
      .detail-offer .image_label .zoom-button.btn-primary {
        border-color: #ff9326;
        background: #ff9326; }
        .detail-offer .image_label .zoom-button.btn-primary:hover {
          border-color: #ff9326;
          background: #ff9326; }
      .detail-offer .image_label .zoom-button span {
        font-size: 20px;
        font-style: normal; }
    .detail-offer .image_label .zoom-button {
      top: 42px;
      left: 65px; }
    .detail-offer .image_label:hover img {
      opacity: 1; }
    .detail-offer .image_label:hover .zoom-button, .detail-offer .image_label:hover .add-button {
      display: block;
      opacity: 1; }
  .detail-offer .description {
    margin-left: 220px;
    width: 775px; }
    .detail-offer .description h3 {
      margin-top: 0; }
    .detail-offer .description p {
      display: block;
      /* max-width: 500px;*/
      white-space: normal;
      word-break: break-word; }
    .detail-offer .description .description-services {
      float: left;
      line-height: 30px;
      padding: 5px 5px;
      /*padding: 5px 10px;
            border-right: 1px solid $gray;*/ }
      .detail-offer .description .description-services img {
        margin-right: 3px; }
    .detail-offer .description .description-services:last-of-type {
      border: none; }
    .detail-offer .description .btn-primary {
      height: initial;
      z-index: 99; }
  .detail-offer .price-wrapper {
    text-align: right; }
    .detail-offer .price-wrapper .price {
      display: block;
      white-space: nowrap; }
      .detail-offer .price-wrapper .price b {
        font-size: 22px;
        display: inline-block; }
    @media (max-width: 992px) {
      .detail-offer .price-wrapper {
        float: right; } }
  .detail-offer .action {
    margin-top: 5px;
    padding-top: 6px;
    padding-bottom: 3px; }
  .detail-offer.modal-body .description-services {
    line-height: 23px;
    padding: 5px 5px; }
    .detail-offer.modal-body .description-services img {
      width: 22px;
      margin-right: 3px; }
  .detail-offer .forward-action {
    text-align: center;
    width: 29px;
    background-color: #44475a;
    color: #ffffff;
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    z-index: 100;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    cursor: pointer; }
    @media (max-width: 992px) {
      .detail-offer .forward-action {
        width: 15%; } }
    .detail-offer .forward-action a {
      display: block;
      height: 100%;
      width: 100%; }
      .detail-offer .forward-action a:hover, .detail-offer .forward-action a:active, .detail-offer .forward-action a:visited {
        color: #ffffff; }
    .detail-offer .forward-action:hover {
      background-color: #ff9326;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }

.detail-offer-header {
  background: #ECECEE;
  padding: 8px;
  margin: 0; }

.package-light-info {
  /*color: $gray-not-so-dark;*/ }
  .package-light-info ul {
    padding-left: 10px; }
    .package-light-info ul li {
      padding: 3px 0;
      list-style-type: none; }
      .package-light-info ul li ul {
        padding-left: 40px; }
        .package-light-info ul li ul li {
          list-style-type: circle; }
      .package-light-info ul li .fa {
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        text-align: center; }
      .package-light-info ul li img.serviceimage {
        margin-right: 10px; }

.highlight-flexible {
  height: initial;
  padding: 10px;
  margin: 10px 5px;
  border: 2px solid #ff9326; }

.detail-booking {
  border: 2px solid #44475a;
  background: #ECECEE;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: table;
  width: 100%;
  margin-bottom: 10px; }
  .detail-booking .price {
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
    padding: 4px 20px 0 0;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out; }
    .detail-booking .price.price-loading:not(:required):before {
      -webkit-transition: all .25s ease-in-out;
      -moz-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
      content: '';
      display: inline-block;
      font-size: 7px;
      width: 7px;
      height: 7px;
      margin: 10px 20px;
      float: left;
      -webkit-animation: spinner 1500ms infinite linear;
      -moz-animation: spinner 1500ms infinite linear;
      -ms-animation: spinner 1500ms infinite linear;
      -o-animation: spinner 1500ms infinite linear;
      animation: spinner 1500ms infinite linear;
      border-radius: 0.5em;
      -webkit-box-shadow: #44444b 1.5em 0 0 0, #44444b 1.1em 1.1em 0 0, #44444b 0 1.5em 0 0, #44444b -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, #44444b 0 -1.5em 0 0, #44444b 1.1em -1.1em 0 0;
      box-shadow: #44444b 1.5em 0 0 0, #44444b 1.1em 1.1em 0 0, #44444b 0 1.5em 0 0, #44444b -1.1em 1.1em 0 0, #44444b -1.5em 0 0 0, #44444b -1.1em -1.1em 0 0, #44444b 0 -1.5em 0 0, #44444b 1.1em -1.1em 0 0; }
  .detail-booking .action {
    padding: 8px 20px 6px 20px;
    font-size: 17px;
    font-weight: bold;
    float: right; }
  .detail-booking .unique-offer-link {
    cursor: pointer;
    display: inline-block;
    width: 150px;
    padding: 10px 10px 0;
    text-align: center;
    float: right; }
  .detail-booking__price {
    padding: 10px;
    border-top: 1px solid #D9D9D9; }
    .detail-booking__price:before, .detail-booking__price:after {
      display: table;
      content: '';
      clear: both; }
  .detail-booking__fields {
    display: table;
    width: 100%;
    margin: 10px auto; }
    @media (max-width: 992px) {
      .detail-booking__fields .field-dates {
        font-size: 12px; } }
    .detail-booking__fields .date-div {
      position: relative; }
      .detail-booking__fields .date-div .fill-dates {
        -webkit-box-shadow: 1px 1px 12px #464759;
        -moz-box-shadow: 1px 1px 12px #464759;
        box-shadow: 1px 1px 12px #464759;
        color: #ffffff;
        background: #006C9F;
        bottom: 84px;
        position: absolute;
        width: 180px;
        left: 40px;
        z-index: 1000;
        border-radius: 2px; }
        @media (max-width: 992px) {
          .detail-booking__fields .date-div .fill-dates {
            bottom: -36px;
            left: 20px; } }
        .detail-booking__fields .date-div .fill-dates .arrow-up {
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #006C9F;
          position: absolute;
          top: -7px;
          left: 84px; }
        .detail-booking__fields .date-div .fill-dates table {
          margin: 3px 0; }
        .detail-booking__fields .date-div .fill-dates .text-td {
          padding: 0px 8px;
          font-size: 14px;
          line-height: 17px; }
          @media (max-width: 992px) {
            .detail-booking__fields .date-div .fill-dates .text-td {
              font-size: 13px; } }
        .detail-booking__fields .date-div .fill-dates .icon-td {
          padding: 5px 8px;
          text-align: center;
          border-right: 1px solid #ffffff;
          font-size: 25px; }
    .detail-booking__fields label {
      font-size: 12px;
      text-transform: uppercase; }
    .detail-booking__fields .form-group {
      margin-bottom: 0px; }
      .detail-booking__fields .form-group .white-radio-group {
        font-size: 13px;
        background: #ffffff;
        width: 100%;
        padding: 0px !important; }
        .detail-booking__fields .form-group .white-radio-group .radio-record {
          padding: 5px;
          margin: 8px 8px 0;
          border: 1px solid #efefef; }
          .detail-booking__fields .form-group .white-radio-group .radio-record:last-of-type {
            margin: 8px; }
          .detail-booking__fields .form-group .white-radio-group .radio-record input[type=radio] {
            margin: 0px 10px !important; }
          .detail-booking__fields .form-group .white-radio-group .radio-record label {
            margin-bottom: 0px;
            text-transform: none;
            display: inline; }
            .detail-booking__fields .form-group .white-radio-group .radio-record label.bold {
              font-weight: bold; }
    .detail-booking__fields .form-group:not(.input-group) {
      /*width: 30%;
            display: table-cell;*/
      padding: 5px 10px; }
      .detail-booking__fields .form-group:not(.input-group).small-fg {
        width: 20%; }
      .detail-booking__fields .form-group:not(.input-group) .form-control, .detail-booking__fields .form-group:not(.input-group) #newsletter_form .dropdown-toggle, #newsletter_form .detail-booking__fields .form-group:not(.input-group) .dropdown-toggle {
        padding: 4px 5px 2px 5px;
        height: 30px;
        font-size: 13px;
        background: #FFF !important; }
        @media (max-width: 992px) {
          .detail-booking__fields .form-group:not(.input-group) .form-control.field-dates, .detail-booking__fields .form-group:not(.input-group) #newsletter_form .field-dates.dropdown-toggle, #newsletter_form .detail-booking__fields .form-group:not(.input-group) .field-dates.dropdown-toggle {
            font-size: 12px; } }
        .detail-booking__fields .form-group:not(.input-group) .form-control.persons-dropdown, .detail-booking__fields .form-group:not(.input-group) #newsletter_form .persons-dropdown.dropdown-toggle, #newsletter_form .detail-booking__fields .form-group:not(.input-group) .persons-dropdown.dropdown-toggle {
          padding: 0 8px; }
  .detail-booking .detail-booking-offer-info {
    font-size: 13px;
    padding: 10px;
    text-align: left;
    color: #ffffff;
    background: #44475a; }

.persons-dropdown .form-group {
  margin: 0 0 10px 0; }

.detail-feature-container {
  overflow: auto;
  padding-top: 1px;
  margin-bottom: 30px; }
  .detail-feature-container .detail-features {
    border: 2px solid #337AB7; }
    .detail-feature-container .detail-features li {
      text-align: center; }
      .detail-feature-container .detail-features li img {
        width: 20px; }
      .detail-feature-container .detail-features li div {
        display: inline-block;
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        width: 100%;
        font-size: 14px;
        height: 25px; }
  .detail-feature-container .detail-features-category li {
    border-top: 1px solid #FFF;
    border-right: 2px solid #FFF;
    color: #44475a;
    background: #ECECEE; }
    .detail-feature-container .detail-features-category li a {
      font-size: 15px;
      color: #44475a;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .detail-feature-container .detail-features-category li:last-of-type {
      border-right: none; }
    .detail-feature-container .detail-features-category li.active {
      color: #ffffff;
      background: #337AB7; }
      .detail-feature-container .detail-features-category li.active a {
        color: #ffffff; }
  .detail-feature-container .detail-features, .detail-feature-container .detail-features-category {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-weight: bold;
    /*border-bottom: 1px solid $gray-light;*/ }
    .detail-feature-container .detail-features:before, .detail-feature-container .detail-features:after, .detail-feature-container .detail-features-category:before, .detail-feature-container .detail-features-category:after {
      clear: both;
      display: table;
      content: ''; }
    .detail-feature-container .detail-features li, .detail-feature-container .detail-features-category li {
      padding: 4px 10px 4px;
      margin: 0;
      width: 20%;
      display: block;
      float: left;
      line-height: 28px; }
      .detail-feature-container .detail-features li i, .detail-feature-container .detail-features-category li i {
        font-size: 20px;
        margin-right: 10px;
        width: 20px; }
      .detail-feature-container .detail-features li.more, .detail-feature-container .detail-features-category li.more {
        float: none;
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: right;
        background: none;
        padding: 5px 20px 0;
        font-weight: normal;
        font-style: italic; }
      @media (max-width: 992px) {
        .detail-feature-container .detail-features li, .detail-feature-container .detail-features-category li {
          width: 33.3333333333%; } }
  .detail-feature-container .detail-features li {
    padding: 5px 5px 5px;
    line-height: 15px; }

.js-modal-accordion.info-accodion {
  overflow: hidden;
  height: 90px;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  position: relative; }

.reservation-accordion-opener {
  text-align: center;
  color: #006C9F;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  height: 30px !important;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 67%, white 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 67%, white 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 67%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  cursor: pointer; }
  .reservation-accordion-opener span {
    padding: 5px 15px 2px 15px;
    border-radius: 10px;
    font-size: 12px;
    background: #445567;
    color: #FFF; }

.detail-page .map {
  margin: 15px 0 0 0; }

.detail-page h3 {
  font-weight: bold; }

.detail-rating {
  cursor: pointer;
  text-align: center;
  float: right; }
  .detail-rating .badge {
    background: #ff9326;
    font-size: 20px;
    color: #FFF;
    border-radius: 30px;
    padding: 10px 15px;
    margin: 0 0 0 10px; }
  .detail-rating a {
    display: block; }

.detail-contact {
  padding-top: 10px;
  line-height: 34px; }
  .detail-contact a, .detail-contact span {
    font-size: 14px; }
  .detail-contact .contact-row.phone-data {
    border: 1px solid #ff9326;
    border-radius: 20px;
    line-height: 21px;
    padding: 2px 9px 2px 4px;
    float: right; }
    .detail-contact .contact-row.phone-data a span, .detail-contact .contact-row.phone-data i {
      font-size: 14px; }
  .detail-contact i.icon-map {
    padding: 5px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    margin-right: 10px;
    margin-left: 10px; }
    @media (max-width: 992px) {
      .detail-contact i.icon-map {
        margin-left: 0; } }
  .detail-contact i.icon-phone {
    padding: 5px;
    width: 40px;
    height: 40px;
    color: #ff9326; }
    @media (max-width: 992px) {
      .detail-contact i.icon-phone {
        margin-left: 0; } }
  @media (max-width: 992px) {
    .detail-contact {
      line-height: 24px; }
      .detail-contact span.contact-row {
        display: block; } }

.detail-image-loader {
  background: rgba(68, 71, 90, 0.7) url("../img/loading.svg") no-repeat center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  opacity: 0;
  transition-duration: .3s; }

.detail-image.loading .detail-image-loader {
  opacity: 1; }

.detail-accordion h3 {
  cursor: pointer; }

.detail-accordion > div {
  display: none;
  position: relative;
  padding-left: 18px; }

.detail-accordion-half div {
  height: 160px;
  overflow: hidden; }

.detail-accordion-half .detail-accordion-opener-up {
  display: none; }

.detail-accordion-half .detail-accordion-opener-down {
  display: inline-block; }

.detail-accordion-half.open .detail-accordion-opener-up {
  display: inline-block; }

.detail-accordion-half.open .detail-accordion-opener-down {
  display: none; }

.detail-accordion-half .detail-accordion-opener-down {
  display: inline-block; }

.detail-accordion-half.open .detail-accordion-opener-down {
  display: none; }

.detail-accordion.show > div {
  display: block; }

.detail-accordion-opener {
  text-align: center;
  padding: 40px 0 0 0;
  color: #006C9F;
  position: absolute;
  left: 0;
  width: 100%;
  height: 80px !important;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 67%, white 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 67%, white 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 67%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  cursor: pointer; }
  .detail-accordion-opener span {
    display: inline-block;
    padding: 5px 15px 2px 15px;
    border-radius: 10px;
    font-size: 12px;
    background: #44475a;
    color: #FFF; }

.detail-poi-container {
  display: inline-block;
  clear: both; }
  .detail-poi-container .detail-poi-row {
    padding-left: 10px;
    margin-bottom: 5px;
    clear: both;
    display: block;
    line-height: 40px; }
    .detail-poi-container .detail-poi-row .detail-poi-icon {
      float: left;
      width: 40px;
      text-align: center; }
    .detail-poi-container .detail-poi-row .detail-poi-data {
      float: left; }

.modal-accordion {
  border-bottom: 1px solid #D9D9D9;
  padding: 0 0 10px 0;
  display: none; }
  .modal-accordion.accept-accordion {
    margin: 10px 10px 0;
    background: #f1f1f3;
    padding: 10px;
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9; }

.package-description {
  overflow: hidden;
  position: relative; }
  .package-description__cover {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 65%, white 100%);
    width: 100%;
    height: 140px;
    position: absolute;
    top: 0;
    text-align: center; }

.mobile-price-wrapper .price {
  margin-bottom: 5px;
  font-size: 16px; }

.modal-backdrop, .modal-backdrop.in {
  background: #DDD;
  opacity: .90; }

.modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); }

.modal-body {
  padding: 10px 20px; }

.modal-header {
  padding: 10px 20px 5px;
  border-color: #D9D9D9; }

.modal-footer {
  padding: 20px 20px; }

.modal-footer {
  text-align: center; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 750px; } }

.popover-html-content {
  display: none; }

.persons-dropdown {
  padding: 4px 10px;
  cursor: pointer;
  position: relative; }
  .persons-dropdown > span {
    display: inline-block;
    margin-right: 0px; }
    .persons-dropdown > span > i {
      font-size: 22px;
      color: #44475a; }
  .persons-dropdown--form-control > span {
    margin-top: -6px; }
  .persons-dropdown:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border: 5px solid transparent;
    border-top: 5px solid #777;
    position: absolute;
    right: 10px;
    top: 16px; }
  .persons-dropdown__menu {
    position: absolute;
    padding: 20px 20px 5px 20px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    top: 100%;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #CCC;
    display: none;
    z-index: 1000; }
    .persons-dropdown__menu:before {
      width: 20px;
      height: 20px;
      border: 10px solid transparent;
      border-bottom: 10px solid #CCC;
      display: block;
      content: '';
      left: 50%;
      margin-left: -10px;
      top: -20px;
      position: absolute;
      z-index: 1001; }
    .persons-dropdown__menu .form-control, .persons-dropdown__menu #newsletter_form .dropdown-toggle, #newsletter_form .persons-dropdown__menu .dropdown-toggle {
      padding: 5px 10px;
      height: 32px; }
    .persons-dropdown__menu .input-group-addon {
      padding: 0 12px; }
    .persons-dropdown__menu i {
      font-size: 22px; }
  .persons-dropdown.open .persons-dropdown__menu {
    display: block; }

.content-review .control-label {
  text-align: left; }

.review-form {
  border: 1px solid #d1d1d6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
  border-radius: 3px; }
  .review-form .review-form-rows > div {
    width: 100%;
    position: relative; }
    .review-form .review-form-rows > div:first-child button {
      display: none; }
    .review-form .review-form-rows > div > i {
      position: absolute;
      top: 8px;
      left: 10px; }
      .review-form .review-form-rows > div > i.icon-minus {
        color: #FC5800; }
      .review-form .review-form-rows > div > i.icon-plus {
        color: #ff9326; }
    .review-form .review-form-rows > div button {
      background: #ECECEE;
      float: right;
      border: 0;
      padding: 10px;
      border-bottom: 1px solid #d1d1d6; }
    .review-form .review-form-rows > div div {
      overflow: hidden; }
    .review-form .review-form-rows > div input {
      padding: 10px 10px 10px 40px;
      width: 100%;
      border: 0;
      font-family: inherit;
      border-bottom: 1px solid #ECECEE;
      background: none; }
      .review-form .review-form-rows > div input:focus, .review-form .review-form-rows > div input:active {
        outline: none;
        background: rgba(236, 236, 238, 0.5); }
  .review-form a {
    display: block;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    padding: 7px;
    background: rgba(236, 236, 238, 0.3); }

.rating-row {
  padding-top: 7px; }
  .rating-row .br-wrapper, .rating-row span {
    display: inline-block; }
  .rating-row select {
    display: none; }
  .rating-row span {
    width: 140px; }
  .rating-row .br-theme-fontawesome-stars .br-widget {
    height: auto; }

/* Absolute Center Spinner */
.map_overlay, .reservation_overlay {
  position: absolute;
  z-index: 1021;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 180px; }

.reservation_overlay {
  margin-top: 50%; }

.map_overlay_bg, .reservation_overlay_bg {
  width: 100%;
  margin-bottom: -400px;
  height: 400px;
  background: #000;
  opacity: .4;
  position: absolute;
  z-index: 1020; }

.reservation_overlay_bg {
  width: 100%;
  height: 100%; }

/* :not(:required) hides these rules from IE9 and below */
.map_overlay:not(:required), .reservation_overlay:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.map_overlay:not(:required):after, .reservation_overlay:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0; }

.loading_overlay {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

/* Transparent Overlay */
.loading_overlay:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

/* :not(:required) hides these rules from IE9 and below */
.loading_overlay:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.loading_overlay:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0; }

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.voucher_wrapper .h1 {
  text-align: center;
  color: #0099cc;
  font-size: 28px;
  margin: 0 0 10px 0;
  font-weight: bold; }

.voucher_wrapper .h2, .voucher_wrapper .h2 a {
  text-align: center;
  color: #0099cc;
  font-size: 15px;
  margin: 0 0 20px 0;
  font-weight: bold; }

.voucher_wrapper h1, .voucher_wrapper h2 {
  margin-top: 0;
  text-align: center;
  color: #0099cc; }

.voucher_wrapper .page-header {
  padding-bottom: 0;
  margin: 0;
  border: 0 none; }

.voucher_wrapper .container {
  text-align: justify; }
  .voucher_wrapper .container .voucher_body_container {
    padding-top: 60px; }
    @media (max-width: 992px) {
      .voucher_wrapper .container .voucher_body_container {
        padding-top: 0; } }
  .voucher_wrapper .container .slider-voucher {
    height: inherit; }
    @media (max-width: 992px) {
      .voucher_wrapper .container .slider-voucher {
        margin-bottom: 20px; } }
  .voucher_wrapper .container .voucher-textside {
    margin: 0 10px 60px; }
    @media (max-width: 992px) {
      .voucher_wrapper .container .voucher-textside {
        margin: 20px 10px;
        border-bottom: 1px solid #f1f1f3; } }
  .voucher_wrapper .container .voucher-formside {
    margin: 0 10px 60px;
    /*padding: 30px 10px;
      background: $gray-light;
      outline: 2px solid $gray-background;*/
    color: #44475a; }
    .voucher_wrapper .container .voucher-formside label {
      font-weight: bold; }
      .voucher_wrapper .container .voucher-formside label span {
        font-weight: normal;
        font-size: 80%;
        font-style: italic; }
    @media (max-width: 992px) {
      .voucher_wrapper .container .voucher-formside {
        margin: 0px 10px 20px; } }
    .voucher_wrapper .container .voucher-formside .voucher-types label {
      margin: 0; }
    .voucher_wrapper .container .voucher-formside .voucher_label {
      padding: 4px;
      margin: 0px;
      position: relative; }
      .voucher_wrapper .container .voucher-formside .voucher_label .zoom-button, .voucher_wrapper .container .voucher-formside .voucher_label .add-button {
        opacity: 0.8;
        position: absolute;
        color: #ffffff;
        z-index: 10;
        display: none;
        text-align: center; }
        .voucher_wrapper .container .voucher-formside .voucher_label .zoom-button.btn-primary, .voucher_wrapper .container .voucher-formside .voucher_label .add-button.btn-primary {
          border-color: #ff9326;
          background: #ff9326; }
          .voucher_wrapper .container .voucher-formside .voucher_label .zoom-button.btn-primary:hover, .voucher_wrapper .container .voucher-formside .voucher_label .add-button.btn-primary:hover {
            border-color: #ff9326;
            background: #ff9326; }
        .voucher_wrapper .container .voucher-formside .voucher_label .zoom-button span, .voucher_wrapper .container .voucher-formside .voucher_label .add-button span {
          font-size: 20px;
          font-style: normal; }
      .voucher_wrapper .container .voucher-formside .voucher_label .zoom-button {
        top: 20px;
        left: 20px; }
      .voucher_wrapper .container .voucher-formside .voucher_label .add-button {
        top: 20px;
        right: 20px; }
      .voucher_wrapper .container .voucher-formside .voucher_label:hover img {
        opacity: 1; }
      .voucher_wrapper .container .voucher-formside .voucher_label:hover .zoom-button, .voucher_wrapper .container .voucher-formside .voucher_label:hover .add-button {
        display: block; }
      .voucher_wrapper .container .voucher-formside .voucher_label.selected {
        background: #ff9326; }
        .voucher_wrapper .container .voucher-formside .voucher_label.selected img {
          opacity: 1; }
      .voucher_wrapper .container .voucher-formside .voucher_label img {
        z-index: 9;
        opacity: 0.8; }
    .voucher_wrapper .container .voucher-formside .voucher_label_secondary {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      display: block;
      text-align: center;
      margin: 0 auto 20px;
      color: #006C9F;
      cursor: pointer; }
    .voucher_wrapper .container .voucher-formside input, .voucher_wrapper .container .voucher-formside select, .voucher_wrapper .container .voucher-formside textarea {
      background: #ffffff; }
      .voucher_wrapper .container .voucher-formside input[type='radio'].voucher, .voucher_wrapper .container .voucher-formside select[type='radio'].voucher, .voucher_wrapper .container .voucher-formside textarea[type='radio'].voucher {
        display: none;
        padding: 0;
        margin: 0; }

.messenger-chat {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 20px;
  text-align: center;
  width: 175px;
  z-index: 1000;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px 20px 0px 20px;
  background-color: rgba(255, 255, 255, 0.8); }
  @media (max-width: 992px) {
    .messenger-chat {
      background: none;
      right: 0;
      width: 100%; } }
  .messenger-chat .first-row {
    margin: 0px auto;
    font-size: 15px;
    color: #FF9326;
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 5s;
    -webkit-animation-name: message-us;
    -webkit-animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    -webkit-animation-iteration-count: infinite;
    -moz-animation-delay: 0s;
    -moz-animation-duration: 5s;
    -moz-animation-name: message-us;
    -moz-animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    -moz-animation-iteration-count: infinite;
    -o-animation-delay: 0s;
    -o-animation-duration: 5s;
    -o-animation-name: message-us;
    -o-animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    -o-animation-iteration-count: infinite;
    animation-delay: 0s;
    animation-duration: 5s;
    animation-name: message-us;
    animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    animation-iteration-count: infinite; }
    @media (max-width: 992px) {
      .messenger-chat .first-row {
        display: none; } }
  .messenger-chat .second-row {
    margin: 0px auto 0;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    -webkit-animation-delay: 0.5s;
    -webkit-animation-duration: 5s;
    -webkit-animation-name: message-us;
    -webkit-animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    -webkit-animation-iteration-count: infinite;
    -moz-animation-delay: 0.5s;
    -moz-animation-duration: 5s;
    -moz-animation-name: message-us;
    -moz-animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    -moz-animation-iteration-count: infinite;
    -o-animation-delay: 0.5s;
    -o-animation-duration: 5s;
    -o-animation-name: message-us;
    -o-animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    -o-animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-duration: 5s;
    animation-name: message-us;
    animation-fill-mode: both;
    /* this prevents the animation from restarting! */
    animation-iteration-count: infinite; }
    @media (max-width: 992px) {
      .messenger-chat .second-row {
        display: none; } }
  .messenger-chat .messenger-row {
    margin-top: 6px;
    background-color: #1786FC;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    line-height: 40px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: -0.4px; }
    @media (max-width: 992px) {
      .messenger-chat .messenger-row {
        margin: 0 auto;
        width: 100px;
        float: right; } }

@font-face {
  font-family: "WorkSans";
  src: url("../fonts/M-2p-light.ttf.eot") format("eot");
  src: url("../fonts/M-2p-light.ttf.woff") format("woff"); }

select {
  /**** SAFARI MIATT *****/
  -webkit-appearance: none; }

*:focus, *:active, *:focus, *:active {
  outline: 0;
  outline: none;
  outline-offset: 0; }

a:focus, button:focus {
  outline: none;
  outline-offset: 0; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

html, body {
  height: 100%; }

body {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  color: #44475a; }
  body.scrolled {
    padding-top: 168px; }
    @media (max-width: 992px) {
      body.scrolled {
        padding-top: 78px; } }

.offer-room:before, .offer-room:after {
  content: '';
  display: table;
  clear: both; }

.nowrap {
  white-space: nowrap; }

.pointer {
  cursor: pointer; }

.navbar-offers {
  background: #44475a;
  white-space: nowrap;
  border: 0;
  margin: 0;
  font-size: 14px;
  min-height: 40px; }
  @media (max-width: 1200px) {
    .navbar-offers {
      font-size: 12px;
      line-height: 20px; } }
  @media (max-width: 992px) {
    .navbar-offers {
      background: #ffffff; }
      .navbar-offers .navbar-nav {
        margin: 0 -15px; }
      .navbar-offers .toggle-menu {
        display: none;
        width: 100%; } }
  .navbar-offers.open .toggle-menu {
    display: block; }

.wrapper {
  /* padding-bottom: 200px;*/
  min-height: 100%; }

.form-control, #newsletter_form .dropdown-toggle {
  border-color: #ECECEE;
  background: #ECECEE;
  padding: 10px 15px;
  height: 38px;
  box-shadow: none;
  border-radius: 2px; }

.input-group-addon {
  border-color: #ECECEE;
  background: #ECECEE; }

.subtitle-row h2, .subtitle-row h3, .subtitle-row h4 {
  margin-top: 10px;
  margin-bottom: 15px; }

.icon-person, .icon-night {
  color: #D9D9D9;
  margin-right: 2px; }
  .icon-person.icon-person, .icon-night.icon-person {
    margin-left: 7px; }

.offer-block-header h2 {
  margin: 0;
  height: 55px;
  padding: 15px 0;
  font-weight: bold; }

.offer-block-header .btn {
  float: right; }

.page-header {
  margin-top: 0; }

.label-text {
  padding-top: 7px;
  display: inline-block; }

.service-links {
  margin: 10px 0; }
  .service-links:before, .service-links:after {
    clear: both;
    display: table;
    content: ''; }
  .service-links a {
    width: 20%;
    outline: 3px solid #FFF;
    display: block;
    float: left;
    padding: 8px 10px;
    background: #ECECEE;
    color: #44475a;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 34px;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .service-links a i {
      font-size: 20px;
      margin-right: 10px;
      width: 20px; }
    @media (max-width: 992px) {
      .service-links a {
        width: 33.3333333333%; } }
    .service-links a:hover {
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      background: #D9D9D9; }

.is_autosend {
  color: #ff9326; }

label {
  font-weight: normal; }

hr {
  border-color: #D9D9D9; }

.icon-star {
  color: #f3d34c;
  vertical-align: super;
  font-size: 70%; }

.sup {
  vertical-align: super;
  font-size: 70%; }

.star-wrapper {
  white-space: nowrap;
  font-size: 12px; }

.angular-google-map-container {
  height: 400px; }

.icon-map {
  z-index: 100; }

.typeahead-group-header {
  padding: 3px 10px;
  border-bottom: 1px solid #aaaaaa;
  font-weight: bold; }

.just4lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  /* fallback */
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical; }

.seasonal-offers {
  border: 1px solid #ECECEE;
  padding: 0 20px;
  font-size: 14px; }
  .seasonal-offers .item {
    margin: 0 -20px;
    padding: 20px; }
    @media (max-width: 1200px) {
      .seasonal-offers .item {
        padding: 5px; } }
    .seasonal-offers .item:before, .seasonal-offers .item:after {
      content: '';
      clear: both;
      display: table; }
    .seasonal-offers .item .item-name {
      display: block;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 23px; }
      .seasonal-offers .item .item-name a {
        color: #006C9F; }
  .seasonal-offers h4 {
    margin: 0 0 8px 0; }
  .seasonal-offers table {
    width: 100%; }
    .seasonal-offers table td {
      width: 50%; }
  .seasonal-offers .image {
    float: left; }
    @media (max-width: 992px) {
      .seasonal-offers .image {
        display: none; } }
    @media only screen and (device-width: 768px) {
      .seasonal-offers .image {
        display: block; } }
  .seasonal-offers .content {
    margin-left: 145px; }
    @media (max-width: 992px) {
      .seasonal-offers .content {
        margin-left: 0; } }
    @media only screen and (device-width: 768px) {
      .seasonal-offers .content {
        margin-left: 145px; } }
  .seasonal-offers .action {
    /*margin: -1px 0 4px 5px;
    @media (max-width: 992px) {*/
    margin: 5px;
    /*}*/ }
  .seasonal-offers .all-offer-link {
    float: right;
    color: #006C9F;
    display: block;
    font-size: 13px; }

.home-destination {
  display: block;
  position: relative;
  margin-bottom: 30px;
  outline: 2px solid #ffffff; }
  .home-destination:hover {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    outline: 2px solid #44475a; }
    .home-destination:hover .forward-action {
      opacity: 1;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
    .home-destination:hover .home-destination-content {
      background: #ffffff; }
  .home-destination .home-destination-image {
    position: relative; }
    .home-destination .home-destination-image .meta {
      position: absolute;
      padding: 40px 15px 10px 15px;
      color: #FFF;
      bottom: 0;
      left: 0;
      width: 100%;
      /*
      background: -moz-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
      background: linear-gradient(to bottom, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
      */
      background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
      background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
      background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, #445567 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0);
      transition: all .3s; }
      .home-destination .home-destination-image .meta h4 {
        margin: 0;
        float: left; }
      .home-destination .home-destination-image .meta .meta-description {
        clear: both; }
      .home-destination .home-destination-image .meta .more-count {
        float: right; }
    .home-destination .home-destination-image:hover .meta {
      padding-top: 10px; }
  .home-destination .home-destination-content {
    min-height: 74px;
    background: #ECECEE;
    text-align: center;
    padding: 20px;
    display: block; }
    .home-destination .home-destination-content h3 {
      margin-top: 0;
      font-weight: normal; }
    .home-destination .home-destination-content .price {
      font-size: 18px;
      font-weight: bold;
      text-align: center; }
  .home-destination .forward-action {
    display: block;
    width: 100%;
    outline: 2px solid #44475a;
    background-color: #44475a;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: -33px;
    color: #ffffff;
    left: 0;
    line-height: 30px;
    z-index: 100;
    opacity: 0; }
    .home-destination .forward-action a {
      color: #ffffff; }

.home-horizontal-featured {
  background: #ECECEE;
  text-align: center;
  display: block;
  transition: all .3s;
  margin-bottom: 20px;
  position: relative; }
  .home-horizontal-featured .percent {
    position: absolute;
    top: -7px;
    left: -3px;
    background-color: #ff9326;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    line-height: 43px;
    width: 43px;
    text-align: center;
    z-index: 1000;
    border: 1px solid #ff9326;
    background: -moz-linear-gradient(top, #ff9326 0%, #f26826 100%);
    background: -webkit-linear-gradient(top, #ff9326 0%, #f26826 100%);
    background: linear-gradient(to bottom, #ff9326 0%, #f26826 100%);
    -moz-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg); }
    @media (max-width: 992px) {
      .home-horizontal-featured .percent {
        top: -6px;
        line-height: 43px;
        width: 43px;
        font-size: 16px;
        left: 0px;
        bottom: inherit;
        right: inherit; } }
  .home-horizontal-featured .price {
    margin-top: 10px;
    font-size: 18px; }
  .home-horizontal-featured:hover {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    outline: 2px solid #44475a;
    background: #ffffff; }
    .home-horizontal-featured:hover .forward-action {
      opacity: 1;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
    .home-horizontal-featured:hover .description .description-inner {
      background-color: #f1f1f3; }
  .home-horizontal-featured a h4 {
    font-size: 20px;
    font-weight: normal; }
  .home-horizontal-featured .image {
    position: relative; }
    .home-horizontal-featured .image .meta {
      padding-top: 30px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      color: #FFF;
      background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
      background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
      background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, #445567 100%);
      /*
          background: -moz-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
          background: -webkit-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
          background: linear-gradient(to bottom, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
          */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0); }
      .home-horizontal-featured .image .meta a {
        color: #ffffff; }
  .home-horizontal-featured .meta-common {
    width: 100%;
    padding: 5px 15px 15px;
    color: #44475a;
    position: relative;
    min-height: 35px; }
    .home-horizontal-featured .meta-common .more-count {
      float: right; }
    .home-horizontal-featured .meta-common .rating {
      float: left; }
  .home-horizontal-featured .forward-action {
    display: block;
    width: 100%;
    outline: 2px solid #44475a;
    background-color: #44475a;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: -33px;
    color: #ffffff;
    left: 0;
    line-height: 30px;
    z-index: 100;
    opacity: 0; }
    .home-horizontal-featured .forward-action a {
      color: #ffffff; }
  .home-horizontal-featured .description {
    height: 45px;
    margin: 5px 0; }
    .home-horizontal-featured .description .description-inner {
      width: 100%;
      padding: 5px 5px;
      background-color: #efefef; }

.price {
  white-space: nowrap; }

.newsletter-text {
  margin-top: 45px; }

.error-msg {
  color: #945;
  margin: 5px 0 0 0;
  padding: 4px;
  border-radius: 2px;
  background: rgba(153, 68, 85, 0.2); }
  .error-msg--dropdown {
    margin: -15px 0 20px 0; }

#newsletter_form .multiselect-parent {
  display: block;
  height: 38px; }

#newsletter_form .caret {
  float: right;
  margin: 6px 0 0 0; }

#newsletter_form .newsletter_dd {
  margin-bottom: 20px; }

#newsletter_form .dropdown-toggle {
  text-align: left;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.awards-contact {
  margin-top: 45px; }
  .awards-contact > a, .awards-contact > span {
    display: inline-block;
    margin: 0 4px;
    vertical-align: top; }
  .awards-contact i {
    color: #44475a;
    font-size: 30px; }
  @media (max-width: 992px) {
    .awards-contact {
      text-align: center; } }

.cl-banner {
  background: rgba(68, 71, 90, 0.95);
  bottom: 0;
  top: auto; }
  @media (max-width: 992px) {
    .cl-banner {
      line-height: 17px;
      font-size: 80%; } }
  .cl-banner .cl-accept {
    background: #49AB80; }
    .cl-banner .cl-accept:hover {
      background: #419973; }
  .cl-banner .cl-policy {
    background: #006C9F; }
    .cl-banner .cl-policy:hover {
      background: #005b86; }
  .cl-banner a {
    background: rgba(255, 255, 255, 0.1);
    padding: 3px 8px;
    margin: 11px 10px 0; }
    .cl-banner a:hover {
      color: #FFF; }

.text-center > .nav-pills > li {
  display: inline-block;
  float: none; }

.messagepage-message {
  padding-bottom: 70px; }

.messagepage-image {
  margin-bottom: 30px;
  display: inline-block; }

.no-package-msg {
  padding: 20px 30px 0;
  text-align: center;
  color: #999; }

.sitemap-page h2 {
  text-transform: uppercase; }

.sitemap-list {
  list-style-type: none;
  margin: 10px 0;
  padding: 0; }
  .sitemap-list > li {
    position: relative;
    border-bottom: 1px solid #EDEDED;
    padding: 5px 0; }
  .sitemap-list .sitemap-dropdown-link i {
    float: right;
    width: 25px;
    height: 25px;
    text-align: center; }

.sitemap-sublist {
  display: none;
  position: absolute;
  background: #FFF;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
  list-style-type: none;
  z-index: 1000;
  border: 1px solid #006C9F;
  text-align: left; }
  .sitemap-sublist.open {
    display: block; }

.reviews .fa-star {
  color: #F9B12D; }

.reviews .review {
  border-bottom: 1px solid #ECECEE;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative; }
  .reviews .review:last-child {
    border: 0; }

.reviews .review-author {
  margin-bottom: 15px; }

.reviews .review-text {
  margin-bottom: 15px; }
  .reviews .review-text:last-child {
    margin-bottom: 30px; }
  .reviews .review-text .fa {
    float: left;
    font-size: 13px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    color: #FFF; }
  .reviews .review-text div {
    margin-left: 40px; }
  .reviews .review-text .fa-plus {
    background-color: #00B285; }
  .reviews .review-text .fa-minus {
    background: #AA2233; }

.reviews .review-stars {
  margin-bottom: 7px; }

.reviews .control-label {
  padding-top: 0; }

.cl-banner a.no-style {
  padding: 0;
  margin: 0;
  background: none; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #006C9F;
  border-color: #006C9F; }

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  color: #006C9F; }

.voucher-list thead tr:first-child {
  border-top: 3px solid #ff9326; }

.voucher-list tbody tr:first-child {
  border-top: 3px solid #000; }

.voucher-list tbody tr:last-child {
  border-bottom: 3px solid #000; }

.voucher-list tfoot tr:last-child {
  border-bottom: 3px solid #ff9326; }

textarea {
  resize: none; }

.text-muted {
  color: #909095; }

.list-lead h2 {
  margin-top: 0; }

a#packagedetail {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden; }

.home-newsletter h1 {
  margin-bottom: 0; }

.book24content .image {
  position: relative; }
  .book24content .image .logo {
    position: absolute;
    top: 20px;
    right: 20px; }
    .book24content .image .logo img {
      width: 200px; }
  .book24content .image .meta {
    font-family: 'WorkSans', sans-serif;
    height: 250px;
    padding: 90px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #FFF;
    background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
    background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, #445567 100%);
    background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, #445567 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0); }
    .book24content .image .meta a {
      color: #ffffff; }
    .book24content .image .meta h2 {
      font-size: 60px;
      text-align: left;
      letter-spacing: -6px;
      font-weight: normal; }
    .book24content .image .meta h3 {
      font-size: 24px;
      letter-spacing: -1px;
      text-align: left;
      font-weight: normal; }

.book24content .small a {
  font-weight: bold; }

@media (max-width: 992px) {
  .book24content .image .meta {
    position: relative;
    background: none;
    color: #000;
    padding: 0px;
    height: unset; }
    .book24content .image .meta h2 {
      font-size: 30px;
      letter-spacing: -2px; }
    .book24content .image .meta h3 {
      font-size: 25px; }
  .book24content .image .logo {
    top: 10px;
    right: 10px; }
    .book24content .image .logo img {
      width: 100px; } }

#newslettersubscribe-modal .modal-dialog {
  position: relative;
  width: 580px;
  top: 100px; }
  #newslettersubscribe-modal .modal-dialog .close {
    position: absolute;
    top: -35px;
    right: 10px;
    font-size: 30px;
    color: #ffffff;
    text-shadow: none;
    opacity: 1;
    z-index: 30; }
  #newslettersubscribe-modal .modal-dialog .modal-content {
    border-radius: 9px;
    min-height: 300px; }
    #newslettersubscribe-modal .modal-dialog .modal-content .nlsm-header {
      border-radius: 9px;
      width: 580px;
      height: 250px;
      position: relative;
      background: url("../img/newsletter-header.png") no-repeat center; }
      #newslettersubscribe-modal .modal-dialog .modal-content .nlsm-header .header-text {
        text-align: center;
        color: #ffffff;
        font-size: 30px;
        position: absolute;
        bottom: 60px;
        width: 100%;
        font-weight: bold; }
    #newslettersubscribe-modal .modal-dialog .modal-content .lead-text-1 {
      margin: 30px 50px 20px;
      font-weight: bold;
      text-align: center;
      font-size: 24px; }
    #newslettersubscribe-modal .modal-dialog .modal-content .lead-text-2 {
      margin: 0 50px;
      padding-bottom: 20px;
      font-size: 14px;
      text-align: center; }
    #newslettersubscribe-modal .modal-dialog .modal-content .modal-nl-form {
      margin: 0 30px;
      padding-bottom: 30px; }
      #newslettersubscribe-modal .modal-dialog .modal-content .modal-nl-form .btn-subsribe {
        width: 100%;
        font-size: 16px;
        margin-bottom: 20px; }
      #newslettersubscribe-modal .modal-dialog .modal-content .modal-nl-form .btn-already-subscribed {
        font-size: 14px;
        color: #959595; }
