.map {
  .map-header {
    background: $gray-light;
    padding: 10px 0;
    color: $dark;
    b, .form-control {
      margin-right: 10px;
      @media (max-width: 992px) {
        margin-bottom: 10px;
      }
    }
    @media (max-width: 992px) {
      padding: 0;
    }
  }
  .form-control {
    background: #FFF;
    border-color: #FFF;
    max-width: 210px;
    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}
