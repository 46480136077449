
.featured-tooltip-content {
  width: 420px;
  min-height: 170px;
  background: #fff;
  border: 1px solid black;
  padding: 15px;
  position: relative;
  margin-left: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
  &__left {
    width: 185px;
    float: left;

    img {
      width: 100%;
      height: 140px;
    }
  }

  &__right {
    padding-left: 10px;
    width: 200px;
    float: right;

    &>h4 {
      font-weight: normal;
      margin-bottom: 0;
    }

    .price {
      position: absolute;
      bottom: 0;
      white-space: unset;
      padding-right: 10px;
    }
  }
  &::after, &::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
  &::before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #000;
    border-width: 11px;
    margin-top: -11px;
  }
}