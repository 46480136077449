.list .item {
  border-bottom: 1px solid $gray-light;
  display: table;
  width: 100%;
  position: relative;
  height: 84px;
  > div {
    position: relative;
    display: table-cell;
    padding: 6px 8px 5px;
    vertical-align: top;
    h3, h4 {
      margin-top: 0;
    }
  }
  .image {
    padding-right: 10px;
    padding-left: 0;
  }
  table {
    width: 100%;
  }
  .content {
    width: 100%;
  }
  .price {
      display: block;
      text-align: right;
      font-size: 16px;
  }
}

.list-lead .text-content a {
  color: $orangeCTA;
}

.list-description {
    position: relative;
    background: #000;
    &__image {
        background-position: center center;
        background-size: cover;
        position: absolute;
        opacity: .5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &__text {
        padding: 50px;
        height: 300px;
        display: table-cell;
        vertical-align: middle;
        position: relative;
        z-index: 300;
        text-align: center;
        color: #FFF;
        margin: auto;
        &_normal {
          text-align: justify;
        }
        h1 {
            margin: 0 0 5px 0;
        }

    }
}

.list {
    &--scrollable {
        overflow: hidden;
    }
    &--lined {
        .item {
          padding: 0;
        }
    }
}

.tab.list .item {
  > div {
    padding: 15px;
    &.image {
      padding-right: 0;
      padding-left: 15px;
    }
  }
}

.list-header {
  margin: 20px 0;
  @media (max-width: 992px) {
    text-align: center;
  }
  &:before, &:after {
    clear: both;
    display: table;
    content: '';
  }

  .pagination {
    float: left;
    margin: 0;
    @media (max-width: 992px) {
      float: none;
      margin: 0 auto 20px;
    }
    > li:first-child a, > li:last-child a, > li:first-child span, > li:last-child span {
      border-radius: 0;
    }
    > li > a {
      padding: 6px 10px;
      @media (max-width: 992px) {
        padding: 7px;
      }
    }
    a {
      &:hover {
        background: transparentize($gray-light, .5);
      }
    }
  }
  .pagination-arrow a {
    background: transparentize($gray-light, .5);
  }
}

.list-lead {
  p {
    text-align: justify;
  }
  @media (max-width: 992px) {
    display: none;
  }
}

.order-container {
  float: right;
  border: 1px solid $blue;
  color: $blue;
  border-radius: 2px;
  line-height: 31px;
  padding-top: 2px;
  &--icon-left {
    margin: 0 10px;
  }
  &--icon-right {
    margin: 2px 3px 0;
  }
  &--select {
    width: 210px !important;
    border: 0 none;
    background: none;
  }
  @media (max-width: 992px) {
    float: none;
    display: inline-block;
    margin: 0 auto;
  }
}
