.slick-arrow-template {
  position: absolute;
  margin: 0;
  background: rgba(255, 255, 255, 0.85);
  font-size: 17px;
  text-align: center;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  line-height: 35px;
  z-index: 200;
  cursor: pointer;
}

.slick-arrow-shadow {
  box-shadow: 3px 0 3px rgba(0, 0, 0, .2);
}

.slider {
  .arrow-left, .arrow-right {
    @extend .slick-arrow-template;
    top: 190px;
    /*@media (max-width: 992px) {
      top: 10%;
    }*/
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  & .arrow-left {
    @extend .slick-arrow-shadow;
    left: 10px;
    padding-right: 3px;
  }
  & .arrow-right {
    @extend .slick-arrow-shadow;
    right: 10px;
    padding-left: 3px;
  }
}
.slick-dots {
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -35px;
  li {
    display: inline-block;
    padding: 7px;
    &.slick-active button {
      background: $highlight;
    }
    button {
      overflow: hidden;
      border: 0;
      padding: 0;
      background: transparentize($dark, .50);
      border-radius: 8px;
      text-indent: 1000px;
      display: block;
      width: 8px;
      height: 8px;
    }
  }
}

.slider-cities.slider {
  .arrow-left, .arrow-right {
    position: initial;
    overflow: hidden;

    &.arrow-right {
      @extend .slick-arrow-shadow;
      position: absolute;
      right: -5px;
      top: 70px;
    }
    &.arrow-left {
      @extend .slick-arrow-shadow;
      position: absolute;
      left: -5px;
      top: 70px;
    }
    @media (max-width: 992px) {
      display: none !important;
    }
  }
}

.detail-image-slider.slider {
  .arrow-left,.detail-image-slider.slider .arrow-right {
    position: initial;

  }
  & .arrow-right {
    @extend .slick-arrow-shadow;
    position: absolute;
    right: -15px;
    top: 15px;
  }
  & .arrow-left {
    @extend .slick-arrow-shadow;
    position: absolute;
    left: -15px;
    top: 15px;
  }
}

.list-body {
  .arrow-left, .arrow-right {
    @extend .slick-arrow-template;
    border: none;

    &.slick-next {
      @extend .slick-arrow-shadow;
      right: -20px;
      bottom: 55px;
    }
    &.slick-prev {
      @extend .slick-arrow-shadow;
      left: -20px;
      bottom: 55px;
    }
  }
}

.detail-page {
  .arrow-left, .arrow-right {
    @extend .slick-arrow-template;
    border: none;
    @media (max-width: 992px) {
      display: none !important;
    }
    &.slick-next {
      @extend .slick-arrow-shadow;
      right: -5px;
      top: 175px;
    }
    &.slick-prev {
      @extend .slick-arrow-shadow;
      left: -5px;
      top: 175px;
    }
  }
}

.slick-arrow {
  display: none;
}

.slider-voucher {
  visibility: hidden;
  height: 300px;
}
.slider-home {
  overflow: hidden;
  .meta {
    padding: 30px 15px 10px 15px !important;
  }
  .percent {
    position: absolute;
    bottom: 47px;
    right: 30px;
    background-color: $orangeCTA;
    border-radius: 5px;
    color: $white;
    font-weight: bold;
    line-height: 43px;
    width: 43px;
    text-align: center;
    z-index: 1000;
    border: 1px solid $orangeCTA;
    background: -moz-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
    @include rotate(-15);
    @media (max-width: 992px) {
      top: 12px;
      left: 10px;
      bottom: inherit;
      right: inherit;
    }
  }
  .meta2 {
    color: #FFF;
    padding: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
    background-color: rgba(68, 71, 90, 0.9);
    @media (max-width: 992px) {
      height: 100px;
      font-size: 16px;
      line-height: 17px;
    }
  }
  .oldprice {
    text-decoration: line-through;
  }
  .currentprice {
    color: $orangeCTA;
    &.bigger {
      font-size: 120%;
    }
  }
  .slider-title .small {
    color: $white;
  }
  &--image {
    @media (max-width: 992px) {
      max-height: 300px;
    }
    @media only screen and (device-width: 768px) {
      max-height: inherit;
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      max-height: inherit;
    }
  }

}

.slick_on_mobile_wrapper {
  position: absolute;
  background: #0000ff;
  width: 100%;
  height: 100%;
  .circle {
    width: 100px;
    height: 100px;
    background: red;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    position: relative;
    left: 50%;
    top: 50%;
  }
}
