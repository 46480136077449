.nav-icon {
  width: 30px;
  height: 24px;
  position: relative;
  margin: 18px 0 12px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;
  @media (max-width: 992px) {
    display: inline-block;
  }
}

.search-icon {
  font-size: 30px;
  margin: 14px 0 0;
  display: inline-block;

}

.nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $gray-dark;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  &.white {
    background: #fff;
  }
}

.nav-icon span:nth-child(1) {
  top: 0px;
}

.nav-icon span:nth-child(2) {
  top: 10px;
}

.nav-icon span:nth-child(3) {
  top: 20px;
}

.nav-icon.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -50px;
}

.nav-icon.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}