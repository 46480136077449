.mb-sm {
  margin-bottom: 10px;
}
.mb-md {
  margin-bottom: 15px;
}
.mb-lg {
  margin-bottom: 20px;
}
.mb-xlg {
  margin-bottom: 40px;
}
.mt-sm {
  margin-top: 10px;
}
.mt-md {
  margin-top: 15px;
}
.mt-lg {
  margin-top: 20px;
}
.mr-sm {
  margin-right: 10px;
}
.mr-md {
  margin-right: 15px;
}

.cpt {
  padding-top: 0;
}
.cmt {
  margin-top: 0;
}

.row.thin {
  margin: 0 -5px;
  [class^='col-'] {
    padding: 0 5px;
  }
}