.form-control {
  background-color: $gray;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1) inset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .1) inset;
  padding: 10px 15px;
  height: 43px;
  &:focus {
    border-color: $orange;
    box-shadow: 0 0 0 3px $gray;
    -webkit-box-shadow: 0 0 0 3px $gray;
    background: #FFF;
  }
  &.form-highlight {
    border: 1px solid $blue;
    background: #FFF;
    color: $blue;
    padding: 0 15px;
  }
}

textarea.form-control {
  height: auto;
}