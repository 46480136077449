.tabs {
  a {
    display: inline-block;
    font-size: 20px;
    padding: 12px 20px;
    color: $text;
    &.active {
      font-weight: bold;
      border-bottom: 2px solid $highlight;
    }
  }
}

.tab-contents {
  border: 1px solid $gray-light;
}