.quote {
  background: $gray-light;
  min-height: 200px;
  font-size: 14px;
  position: relative;
  .content {
    padding: 15px;
  }
  .author {
    display: block;
    margin-top: 5px;
    color: $gray-dark;
    text-align: right;
  }
  .info {
    border-top: 1px solid $gray;
    padding: 15px;
    img {
      float: left;
      max-width: 70px;
      max-height: 50px;
    }
    > div {
      margin-left: 80px;
      min-height: 50px;
    }
    h4 {
      margin: 0;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    bottom: -20px;
    width: 20px;
    height: 20px;
    border: 10px solid transparent;
    border-left: 10px solid $gray-light;
    border-top: 10px solid $gray-light;
  }
  .badge {
    background: $orangeCTA;
    color: #FFF;
  }
}

.detail-page .quote {
  min-height: auto;
  margin-bottom: 30px;
  .just4lines {
    margin-left: 50px;
  }
}
