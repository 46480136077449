@import "colors";
@import "layout-helper";
@import "icons";
/** Elements, layout partials **/
@import "header";
@import "tooltip";
@import "footer";
@import "offers";
@import "cities";
@import "tabs-offer";
@import "typo";
@import "list";
@import "map";
@import "quote";
@import "buttons";
@import "forms";
@import "navicon";
@import "slider";
@import "daterangepicker";
@import "filters";
@import "item";
@import "pagination";
@import "detail";
@import "modal";
@import "popover";
@import "persons-dropdown";
@import "review-form";
@import "loadingoverlay";
@import "voucher";
@import "messenger-chat";


@font-face {
  font-family: "WorkSans";
  src: url("../fonts/M-2p-light.ttf.eot") format("eot");
  src: url("../fonts/M-2p-light.ttf.woff") format("woff");
}


select {
  /**** SAFARI MIATT *****/
  -webkit-appearance:none;
}

*:focus, *:active, *:focus, *:active {
  outline: 0;
  outline: none;
  outline-offset: 0;
}

a:focus, button:focus {
  outline: none;
  outline-offset: 0;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

html, body {
  height: 100%;
}

body {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  color: $text;
  &.scrolled {
    padding-top: 168px;
    @media (max-width: 992px) {
      padding-top: 78px;
    }
  }
}

%clearfix {
  &:before, &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.navbar-offers {
  background: $gray-dark;
  white-space: nowrap;
  border: 0;
  margin: 0;
  font-size: 14px;
  min-height: 40px;
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 20px;
  }
  @media (max-width: 992px) {
    background: $white;
    .navbar-nav {
      margin: 0 -15px;
    }
    .toggle-menu {
      display: none;
      width: 100%;
    }
  }
  &.open .toggle-menu {
    display: block;
  }
}

.wrapper {
  /* padding-bottom: 200px;*/
  min-height: 100%;
}

.form-control {
  border-color: $gray-light;
  background: $gray-light;
  padding: 10px 15px;
  height: 38px;
  box-shadow: none;
  border-radius: 2px;
}

.input-group-addon {
  border-color: $gray-light;
  background: $gray-light;
}

.subtitle-row {
  h2, h3, h4 {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.icon-person, .icon-night {
  color: $gray;
  margin-right: 2px;
  &.icon-person {
    margin-left: 7px;
  }
}

.offer-block-header {
  h2 {
    margin: 0;
    height: 55px;
    padding: 15px 0;
    font-weight: bold;
  }
  .btn {
    float: right;
  }
}

.page-header {
  margin-top: 0;
}

.label-text {
  padding-top: 7px;
  display: inline-block;
}

.service-links {
  margin: 10px 0;
  &:before, &:after {
    clear: both;
    display: table;
    content: '';
  }
  a {
    width: (100%/5);
    outline: 3px solid #FFF;
    display: block;
    float: left;
    padding: 8px 10px;
    background: $gray-light;
    color: $text;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
    line-height: 34px;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    i {
      font-size: 20px;
      margin-right: 10px;
      width: 20px;
    }
    @media (max-width: 992px) {
      width: (100%/3);
    }
    &:hover {
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      background: $gray;
    }
  }
}

.is_autosend {
  color: $orangeCTA;
}

label {
  font-weight: normal;
}

hr {
  border-color: $gray;
}

.icon-star {
  color: $stars;
  vertical-align: super;
  font-size: 70%;
}

.sup {
  vertical-align: super;
  font-size: 70%;
}

.star-wrapper {
  white-space: nowrap;
  font-size: 12px;
}

.angular-google-map-container {
  height: 400px;
}

.icon-map {
  z-index: 100;
}

.typeahead-group-header {
  padding: 3px 10px;
  border-bottom: 1px solid #aaaaaa;
  font-weight: bold;
}

.just4lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;     /* fallback */
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.seasonal-offers {
  border: 1px solid $gray-light;
  padding: 0 20px;
  font-size: 14px;
  .item {
    margin: 0 -20px;
    padding: 20px;
    @media (max-width: 1200px) {
      padding: 5px;
    }
    &:before, &:after {
      content: '';
      clear: both;
      display: table;
    }
    .item-name {
      display: block;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 23px;

      a {
        color: $blue;
      }
    }
  }
  h4 {
    margin: 0 0 8px 0;
  }
  table {
    width: 100%;
    td {
      width: 50%;
    }
  }
  .image {
    float: left;
    @media (max-width: 992px) {
      display: none;
    }
    @media only screen and (device-width: 768px) {
        display: block;
    }
  }
  .content {
    margin-left: 145px;
    @media (max-width: 992px) {
      margin-left: 0;
    }
    @media only screen and (device-width: 768px) {
      margin-left: 145px;
    }

  }
  .action {
    /*margin: -1px 0 4px 5px;
    @media (max-width: 992px) {*/
    margin: 5px;
    /*}*/
  }
  .all-offer-link {
    float: right;
    color: $link;
    display: block;
    font-size: 13px;
  }
}

.home-destination {
  display: block;
  position: relative;
  margin-bottom: 30px;
  outline: 2px solid $white;

  &:hover {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
    outline: 2px solid $gray-background;
    .forward-action {
      opacity: 1;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

    }
    .home-destination-content {
      background: $white;
    }
  }
  .home-destination-image {
    position: relative;
    .meta {
      position: absolute;
      padding: 40px 15px 10px 15px;
      color: #FFF;
      bottom: 0;
      left: 0;
      width: 100%;
      /*
      background: -moz-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
      background: linear-gradient(to bottom, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
      */
      background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);

      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0);
      transition: all .3s;
      h4 {
        margin: 0;
        float: left;
      }
      .meta-description {
        clear: both;
      }
      .more-count {
        float: right;
      }
    }
    &:hover .meta{
      padding-top: 10px;
    }
  }
  .home-destination-content {
    min-height: 74px;
    background: $gray-light;
    text-align: center;
    padding: 20px;
    display: block;
    h3 {
      margin-top: 0;
      font-weight: normal;
    }
    .price {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
  }
  .forward-action {
    display: block;
    width: 100%;
    outline: 2px solid $gray-background;
    background-color: $gray-background;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
    position: absolute;
    bottom: -33px;
    color: $white;
    left: 0;
    line-height: 30px;
    z-index: 100;
    opacity: 0;
    a {
      color: $white;
    }
  }
}

.home-horizontal-featured {
  background: $gray-light;
  text-align: center;
  display: block;
  transition: all .3s;
  margin-bottom: 20px;
  position: relative;
  .percent {
    position: absolute;
    top: -7px;
    left: -3px;
    background-color: $orangeCTA;
    border-radius: 5px;
    color: $white;
    font-weight: bold;
    line-height: 43px;
    width: 43px;
    text-align: center;
    z-index: 1000;
    border: 1px solid $orangeCTA;
    background: -moz-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 147, 38, 1) 0%, rgba(242, 104, 38, 1) 100%);
    @include rotate(-15);
    @media (max-width: 992px) {
      top: -6px;
      line-height: 43px;
      width: 43px;
      font-size: 16px;
      left: 0px;
      bottom: inherit;
      right: inherit;
    }
  }
  .price {
    margin-top: 10px;
    font-size: 18px;
  }
  &:hover {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
    outline: 2px solid $gray-background;
    background: $white;
    .forward-action {
      opacity: 1;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

    }
    .description .description-inner {
      background-color: $gray-extra-light;
    }
  }
  a {

    h4 {
      font-size: 20px;
      font-weight: normal;
    }
  }
  .image {
    position: relative;
    .meta {
      padding-top: 30px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      color: #FFF;
      background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      /*
          background: -moz-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
          background: -webkit-linear-gradient(top, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
          background: linear-gradient(to bottom, rgba(68, 71, 90, 0) 0%, rgba(68, 71, 90, 1) 100%);
          */

      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0);
      a {
        color: $white;
      }
    }
  }
  .meta-common {
    width: 100%;
    padding: 5px 15px 15px;
    color: $text;
    position: relative;
    min-height: 35px;
    .more-count {
      float: right;
    }
    .rating {
      float: left;
    }
  }
  .forward-action {
    display: block;
    width: 100%;
    outline: 2px solid $gray-background;
    background-color: $gray-background;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
    position: absolute;
    bottom: -33px;
    color: $white;
    left: 0;
    line-height: 30px;
    z-index: 100;
    opacity: 0;
    a {
      color: $white;
    }
  }
  .description {
    height: 45px;
    margin: 5px 0;
    .description-inner {
      width: 100%;
      padding: 5px 5px;
      background-color: #efefef;
    }
  }
}

.price {
  white-space: nowrap;
}

.newsletter-text {
  margin-top: 45px;
}

.error-msg {
  color: #945;
  margin: 5px 0 0 0;
  padding: 4px;
  border-radius: 2px;
  background: transparentize(#945, .8);
  &--dropdown {
    margin: -15px 0 20px 0;
  }
}

#newsletter_form {
  .multiselect-parent {
    display: block;
    height: 38px;
  }
  .caret {
    float: right;
    margin: 6px 0 0 0;
  }
  .newsletter_dd {
    margin-bottom: 20px;
  }
  .dropdown-toggle {
    @extend .form-control;
    text-align: left;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // border-color: #ECECEE;
    // background: #ECECEE;
    // padding: 10px 15px;
    // height: 38px;
    // box-shadow: none;
    // border-radius: 2px;
  }
}

.awards-contact {
  margin-top: 45px;
  > a, > span {
    display: inline-block;
    margin: 0 4px;
    vertical-align: top;
  }
  i {
    color: $gray-dark;
    font-size: 30px;
  }
  @media (max-width: 992px) {
    text-align: center;
  }
}

.cl-banner {
  background: transparentize($text, .05);
  bottom: 0;
  top: auto;
  @media (max-width: 992px) {
    line-height: 17px;
    font-size: 80%;
  }
  .cl-accept {
    background: #49AB80;
    &:hover {
      background: darken(#49AB80, 5);
    }
  }
  .cl-policy {
    background: #006C9F;
    &:hover {
      background: darken(#006C9F, 5);
    }
  }
  a {
    background: transparentize(#FFF, .9);
    padding: 3px 8px;
    margin: 11px 10px 0;
    &:hover {
      color: #FFF;
    }
  }
}

.text-center > .nav-pills > li {
  display: inline-block;
  float: none;
}

.messagepage-message {
  padding-bottom: 70px;
}

.messagepage-image {
  margin-bottom: 30px;
  display: inline-block;
}

.no-package-msg {
  padding: 20px 30px 0;
  text-align: center;
  color: #999;
}

.sitemap-page {
  h2 {
    text-transform: uppercase;
  }
}

.sitemap-list {
  list-style-type: none;
  margin: 10px 0;
  padding: 0;
  > li {
    position: relative;
    border-bottom: 1px solid #EDEDED;
    padding: 5px 0;
  }
  .sitemap-dropdown-link i {
    float: right;
    width: 25px;
    height: 25px;
    text-align: center;
  }
}

.sitemap-sublist {
  display: none;
  position: absolute;
  background: #FFF;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  padding: 10px;
  list-style-type: none;
  z-index: 1000;
  border: 1px solid $blue;
  text-align: left;
  &.open {
    display: block;
  }
}

.reviews {
  .fa-star {
    color: $yellow;
  }
  .review {
    border-bottom: 1px solid #ECECEE;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    &:last-child {
      border: 0;
    }
  }
  .review-author {
    margin-bottom: 15px;
  }
  .review-text {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 30px;
    }
    .fa {
      float: left;
      font-size: 13px;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      line-height: 22px;
      color: #FFF;
    }
    div {
      margin-left: 40px;
    }
    .fa-plus {
      background-color: $green;
    }
    .fa-minus {
      background: $red;
    }
  }
  .review-stars {
    margin-bottom: 7px;
  }
  .control-label {
    padding-top: 0;
  }
}

.cl-banner a.no-style {
  padding: 0;
  margin: 0;
  background: none;
}

// paginator
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: $blue;
  border-color: $blue;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
  color: $blue;
}

.voucher-list thead tr {
  &:first-child {
    border-top: 3px solid $orangeCTA;
  }
}
.voucher-list tbody tr {
  &:first-child {
    border-top: 3px solid #000;
  }
  &:last-child {
    border-bottom: 3px solid #000;
  }
}

.voucher-list tfoot tr {
  &:last-child {
    border-bottom: 3px solid $orangeCTA;
  }
}

textarea {
  resize: none;
}

.page_content {
  //text-align: justify;
}

.description {
  //text-align: justify;
}

.text-muted {
  color: $muted;
}

.list-lead {
  h2 {
    margin-top: 0;
  }
}

a#packagedetail {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

.home-newsletter {
  h1 {
    margin-bottom: 0;
  }
}

.book24content {
  .image {
    position: relative;
    .logo {
      img {
        width: 200px;
      }
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .meta {
      font-family: 'WorkSans', sans-serif;
      height: 250px;
      padding: 90px 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #FFF;
      background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00122441', endColorstr='#122441', GradientType=0);
      a {
        color: $white;
      }
      h2 {
        font-size: 60px;
        text-align: left;
        letter-spacing: -6px;
        font-weight: normal;
      }
      h3 {
        font-size: 24px;
        letter-spacing: -1px;
        text-align: left;
        font-weight: normal;
      }
    }
  }
  .small a {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    .image {
      .meta {
        position: relative;
        background: none;
        color: #000;
        padding: 0px;
        height: unset;
        h2 {
          font-size: 30px;
          letter-spacing: -2px;
        }
        h3 {
          font-size: 25px;
        }
      }
      .logo {
        img {
          width: 100px;
        }
        top: 10px;
        right: 10px;
      }
    }
  }
}

#newslettersubscribe-modal {
  .modal-dialog {
    position: relative;
    .close {
      position: absolute;
      top: -35px;
      right: 10px;
      font-size: 30px;
      color: $white;
      text-shadow: none;
      opacity: 1;
      z-index: 30;
    }
    width: 580px;
    top: 100px;
    .modal-content {
      border-radius: 9px;
      min-height: 300px;
      .nlsm-header {
        border-radius: 9px;
        width: 580px;
        height: 250px;
        position: relative;
        background: url('../img/newsletter-header.png') no-repeat center;
        .header-text {
          text-align: center;
          color: $white;
          font-size: 30px;
          position: absolute;
          bottom: 60px;
          width: 100%;
          font-weight: bold;
        }
      }
      .lead-text-1 {
        margin: 30px 50px 20px;
        font-weight: bold;
        text-align: center;
        font-size: 24px;
      }
      .lead-text-2 {
        margin: 0 50px;
        padding-bottom: 20px;
        font-size: 14px;
        text-align: center;
      }
      .modal-nl-form {
        margin: 0 30px;
        padding-bottom: 30px;
        .btn-subsribe {
          width: 100%;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .btn-already-subscribed {
          font-size: 14px;
          color: $gray-not-so-dark;
        }
      }
    }
  }
}