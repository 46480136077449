h1, h2, h3, h4 {
    font-weight: bold;
}

h1 {
  font-size: 28px;
  margin: 0 0 20px 0;
  @media (max-width: 1100px) {
    font-size: 24px;
  }
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

a {
  color: $dark;
  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $dark;
  }
}
