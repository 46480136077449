.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: $blue;
}
.range_inputs .btn-sm {
  height: 30px;
}

.daterangepicker_input {
  @media (max-width: 992px) {
    display: none;
  }
}
.calendar.right {
  @media (max-width: 992px) {
    border-top: 1px solid $gray
  }
}

.calendar-table .table-condensed>tbody>tr>td,
.calendar-table .table-condensed>tbody>tr>th,
.calendar-table .table-condensed>tfoot>tr>td,
.calendar-table .table-condensed>tfoot>tr>th,
.calendar-table .table-condensed>thead>tr>td,
.calendar-table .table-condensed>thead>tr>th {
  @media (max-width: 992px) {
    padding: 2px 5px;
  }
}

.daterangepicker.dropdown-menu {
  z-index: 2060;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: $gray-disabled;
  /* font-style: italic; */
  text-align: center;
}

.datepicker {
  .datepicker-days {
    .day {
      width: 29px;
      border-radius: 0;
      &.active {
        background: none;
        text-shadow: none;
        background-repeat: no-repeat;
        color: #44475a;
        &:hover {
          background: #eeeeee;
        }
      }
    }
    .selected-day {
      background-color: #ffdebd;
    }
    .end-day {
      background-image: url("/img/datepicker_end.png") !important;
      background-repeat: no-repeat;
      .available-day {
        background-color: #e5ffe6 !important;
      }
    }
    .start-day {
      background-image: url("/img/datepicker_start.png") !important;
      background-repeat: no-repeat;
      .available-day {
        background-color: #e5ffe6 !important;
      }
    }
    .available-day {
      background-color: #e5ffe6;
    }
  }
}

.szallodak-datepicker {
  font-weight: bold;
  i.icon-calendar {
    font-weight: bold;
  }
  input {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
  span.erase-date {
    right: 7px;
    top: 14px;
    cursor: pointer;
  }
}

.header-searchform {
  .szallodak-datepicker {
    .input-group-addon {
      padding-right: 0;
      padding-left: 8px;
      height: 42px;
      &.right-addon {
        padding-left: 20px;
      }
    }
    input {
      height: 42px;
    }
  }
}

.reservation-modal-body {
  .szallodak-datepicker {
    .input-group-addon {
      &.left-addon {
        padding-right: 0;
      }
      &.right-addon {
        display: none;
      }
    }
    span.erase-date {
      top: 12px;
    }
  }
}

.detail-booking {
  .szallodak-datepicker {
    .input-group-addon {
      &.left-addon {
        display: none;
      }
      &.right-addon {
        background-color: #ffffff;
      }
      border: 0px none;
    }
    input {
      border-right: 0;
    }
    span.erase-date {
      top: 9px;
    }
  }
}
