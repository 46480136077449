.filter-toggle {
  font-size: 20px;
  color: #FFF;
  background: $orangeCTA;
  border-radius: 3px;
  text-align: left;
  padding: 3px 15px;
  display: none;
  margin: -15px 0 30px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .2);
  i {
    line-height: 35px;
  }
}
.filter-toggle-icon {
  float: right;
  img {
    height: 22px;
  }
}

@media (max-width: 992px) {
  .filter-toggle {
    display: block;
  }
}

.filter-open.filter-toggle {
}

.filters {
  padding-top: 20px;
  position: relative;
  z-index: 15;
}

@media(max-width: 992px) {
  .filters {
    background: $gray-extra-light;
    position: absolute;
    display: table;
    padding: 30px 20px;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: 999;
    transform: translate(0, -1250px);
    -webkit-transform: translate(0px, -1250px);
    transition: .5s ease-in-out;
    border-bottom: 2px solid $orangeCTA;
    &.open {
      transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      box-shadow: 5px 0 20px rgba(0, 0, 0, .2);
    }
  }
}

.filter-block {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid $gray-light;
  .icon-minus-circle,
  .icon-plus-circle {
    color: $blue;
    margin: 2px 5px 0 0;
  }
  .icon-minus-circle {
    display: none;
  }
  .icon-plus-circle {
    display: inline-block;
  }
  .filter-content {
    display: none;
  }
  &.open {
    .filter-content {
      display: block;
    }
    .icon-minus-circle {
      display: inline-block;
    }
    .icon-plus-circle {
      display: none;
    }
  }
  &.desktop-open {
    .filter-content {
      display: block;
    }
    &.open {
      .icon-minus-circle {
        display: inline-block;
      }
      .icon-plus-circle {
        display: none;
      }
    }
  }
  &.desktop-open {
    @media(max-width: 992px) {
      .filter-content {
        display: none;
      }
      .icon-minus-circle {
        display: none;
      }
      .icon-plus-circle {
        display: inline-block;
      }
    }
  }
}

.filter-content {
  padding: 10px 0;
  font-size: 14px;
}

.filter-title {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  h3 {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 0 0;
    line-height: 20px;
    vertical-align: top;
  }
  > a {
    float: right;
    display: block;
    color: $gray-dark;
    font-size: 15px;
  }
  .badge {
    padding: 5px 7px 3px;
    line-height: 12px;
    background: $orangeCTA;
  }
}

.filter-input {
  span, input {
    width: 50%;
    display: inline-block;
    margin-bottom: 5px;
  }
  input {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.filter-group-title {
  border-top: 1px solid $gray-light;
  font-weight: bold;
  font-size: 13px;
  padding: 5px 0;
  display: block;
  text-transform: uppercase;
  margin: 15px 0 0 0;
  &:first-child {
    border: 0;
    margin-top: 0;
  }
}

.icheckbox_flat, .iradio_flat {
  //background-image: url(../css/icheck/flat-custom.png);
  vertical-align: top;
  margin-right: 12px;
}

.filter-radio .btn {
  font-size: 13px;
}

.filter-label {
  font-size: 14px;
  font-weight: bold;
  display: block;
  width: 100%;
  border-bottom: 1px solid $gray-extra-light;
  padding: 10px 0 0 10px;
}

.filter-radio, .filter-checkbox {
  margin-bottom: 5px;
  display: block;
  .btn {
    margin-right: 5px;
    background: $gray-light;
    border-color: $gray-light;
    &:focus {
      outline: none;
    }
    &.checked {
      background: $highlight;
      color: #FFF;
    }
  }
}

.filter-checkbox {
  display: inline-block;
  i {
    vertical-align: baseline;
    font-size: 15px;
    color: $highlight;
  }
}

.filter-slider-text {
  display: block;
  font-size: 13px;
  text-align: center;
}

.list-order {
  float: right;
  width: 180px;
  select {
    width: 100%;
  }
}

rzslider .rz-pointer {
  background-color: $blue;
  width: 10px;
  height: 24px;
  top: -10px;
  border-radius: 0;
  &:after {
    display: none;
  }
}

rzslider .rz-bar.rz-selection {
  background: $blue;
}