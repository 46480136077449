.voucher_wrapper {
  .h1 {
    text-align: center;
    color: $voucher_hl;
    font-size: 28px;
    margin: 0 0 10px 0;
    font-weight: bold;
  }
  .h2, .h2 a {
    text-align: center;
    color: $voucher_hl;
    font-size: 15px;
    margin: 0 0 20px 0;
    font-weight: bold;
  }
  h1, h2 {
    margin-top: 0;
    text-align: center;
    color: $voucher_hl;
  }
  .page-header {
    padding-bottom: 0;
    margin: 0;
    border: 0 none;
  }
  .container {
    text-align: justify;

    .voucher_body_container {
      padding-top: 60px;
      @media (max-width: 992px) {
        padding-top: 0;
      }
    }

    .slider-voucher {
      height: inherit;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .voucher-textside {
      margin: 0 10px 60px;
      @media (max-width: 992px) {
        margin: 20px 10px;
        border-bottom: 1px solid $gray-extra-light;
      }
    }
    .voucher-formside {
      margin: 0 10px 60px;
      /*padding: 30px 10px;
      background: $gray-light;
      outline: 2px solid $gray-background;*/
      color: $gray-background;
      label {
        font-weight: bold;
        span {
          font-weight: normal;
          font-size: 80%;
          font-style: italic;
        }
      }

      @media (max-width: 992px) {
        margin: 0px 10px 20px;
      }
      .voucher-types {
        label {
          margin: 0;
        }
      }
      .voucher_label {
        padding: 4px;
        margin: 0px;
        position: relative;
        .zoom-button, .add-button {
          opacity: 0.8;
          position: absolute;
          color: $white;
          z-index: 10;
          display: none;
          text-align: center;
          &.btn-primary {
            &:hover {
              border-color: $orangeCTA;
              background: $orangeCTA;
            }
            border-color: $orangeCTA;
            background: $orangeCTA;
          }
          span {
            font-size: 20px;
            font-style: normal;
          }
        }
        .zoom-button {
          top: 20px;
          left: 20px;
        }
        .add-button {
          top: 20px;
          right: 20px;
        }
        &:hover {
          img {
            opacity: 1;
          }
          .zoom-button, .add-button {
            display: block;
          }
        }
        &.selected {
          img {
            opacity: 1;
          }
          background: $orangeCTA;
        }
        img {
          z-index: 9;
          opacity: 0.8;
        }
      }
      .voucher_label_secondary {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        display: block;
        text-align: center;
        margin: 0 auto 20px;
        color: $blue;
        cursor: pointer;
      }
      input, select, textarea {
        background: $white;
        &[type='radio'].voucher {
          display: none;
          padding: 0;
          margin: 0;
        }
      }
    }
  }


}