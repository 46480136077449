.content-review {
  .control-label {
    text-align: left;
  }
}

.review-form {
  border: 1px solid darken($gray-light, 10);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .2) inset;
  border-radius: 3px;
  .review-form-rows > div {
    width: 100%;
    position: relative;
    &:first-child button {
      display: none;
    }
    > i {
      position: absolute;
      top: 8px;
      left: 10px;
      &.icon-minus {
        color: $orange;
      }
      &.icon-plus {
        color: $orangeCTA;
      }
    }
    button {
      background: $gray-light;
      float: right;
      border: 0;
      padding: 10px;
      border-bottom: 1px solid darken($gray-light, 10)
    }
    div {
      overflow: hidden;
    }
    input {
      padding: 10px 10px 10px 40px;
      width: 100%;
      border: 0;
      font-family: inherit;
      border-bottom: 1px solid $gray-light;
      background: none;
      &:focus, &:active {
        outline: none;
        background: transparentize($gray-light, .50);
      }
    }
  }
  a {
    display: block;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    padding: 7px;
    background: transparentize($gray-light, .70);
  }
}

.rating-row {
    padding-top: 7px;
    .br-wrapper, span {
        display: inline-block;
    }
    select {
        display: none;
    }
    span {
        width: 140px;
    }
    .br-theme-fontawesome-stars .br-widget {
        height: auto;
    }
}
