.footer {
  padding: 0;
  /*margin-top: -170px;*/
  .icon-facebook {
    font-size: 30px;
    color: $gray-dark;
    float: right;
  }
  .container {
    position: relative;
  }
  nav {
    margin: 10px 0;
    font-size: 13px;
    text-align: center;
    a {
      display: inline-block;
      padding: 2px 10px;
      border-right: 1px solid $gray;
      color: $text;
      &:last-child {
        border: 0;
      }
    }
  }
  .secondary {
    background: $footer;
    padding: 15px 0;
    margin: 0;
    nav {
      a {
        border-color: rgba(255, 255, 255, .2);
        color: #FFF;
      }
    }
  }
  .award-logo {
    float: right;
    margin-top: -20px;
  }
  .copy {
    color: rgba(255, 255, 255, .5);
    font-size: 13px;
    text-align: center;
    padding: 10px 0;
    a {
      color: rgba(255, 255, 255, .5);
    }
  }
  .tabs {
    background: $purple-light;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    bottom: -15px;
    right: 20px;
    a {
      display: inline-block;
      padding: 7px 20px;
      font-size: 16px;
      color: #FFF;
      border-right: 1px solid rgba(255, 255, 255, .2);
      text-transform: uppercase;
      &:last-child {
        border: 0;
      }
      i {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }
}
