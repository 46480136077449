.pagination {
  > li > a, > li > span {
    border: 0;
    border-right: 1px solid $gray-light;
    margin-left: 0;
    cursor: pointer;
  }
  > li:last-of-type > a {
    border-right: none;
  }
  > li:last-of-type > span {
    border-right: none;
  }
}
.pagination-counter {
  float: left;
  font-size: 12px;
  line-height: 33px;
  margin-right: 20px;
  padding: 0 15px;
  color: $text;
  @media (max-width: 992px) {
    display: block;
    margin: 10px auto;
    float: none;
  }
}

.order-icon {
  float: right;
}