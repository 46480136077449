.persons-dropdown {
  padding: 4px 10px;
  cursor: pointer;
  position: relative;
  > span {
    display: inline-block;
    margin-right: 0px;
    > i {
      font-size: 22px;
      color: $gray-dark;
    }
  }
  &--form-control > span{
      margin-top: -6px;
  }
  &:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border: 5px solid transparent;
      border-top: 5px solid #777;
      position: absolute;
      right: 10px;
      top: 16px;
  }
  &__menu {
    position: absolute;
    padding: 20px 20px 5px 20px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    top: 100%;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    border: 1px solid #CCC;
    display: none;
    z-index: 1000;
    &:before {
      width: 20px;
      height: 20px;
      border: 10px solid transparent;
      border-bottom: 10px solid #CCC;
      display: block;
      content: '';
      left: 50%;
      margin-left: -10px;
      top: -20px;
      position: absolute;
      z-index: 1001;
    }
    .form-control {
      padding: 5px 10px;
      height: 32px;
    }
    .input-group-addon {
      padding: 0 12px;
    }
    i {
      font-size: 22px;
    }
  }
  &.open &__menu {
    display: block;
  }
}
