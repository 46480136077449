.city {
  position: relative;
  display: block;
  height: 175px;
  .meta {
    position: absolute;
    padding: 40px 15px 10px 15px;
    color: #FFF;
    bottom: 0;
    left: 0;
    width: 100%;
    background: -moz-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
    background: linear-gradient(to bottom, rgba(68, 85, 103, 0) 0%, rgba(68, 85, 103, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0044475a', endColorstr='#44475a', GradientType=0);
    transition: all .3s;
    h4 {
      margin: 0;
      float: left;
    }
    .meta-description {
      clear: both;
    }
    .more-count {
      float: right;
    }
  }
  &:hover {
    .meta{
      padding-top: 10px;
    }
  }
}
