@font-face {
    font-family: 'szallodak';
    src:    url('../fonts/szallodak.eot?c5e5xt');
    src:    url('../fonts/szallodak.eot?c5e5xt#iefix') format('embedded-opentype'),
        url('../fonts/szallodak.ttf?c5e5xt') format('truetype'),
        url('../fonts/szallodak.woff?c5e5xt') format('woff'),
        url('../fonts/szallodak.svg?c5e5xt#szallodak') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'szallodak' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-adult:before {
    content: "\e900";
}
.icon-aircondition:before {
    content: "\e901";
}
.icon-arrow-right:before {
    content: "\e902";
}
.icon-baby:before {
    content: "\e903";
}
.icon-babyface:before {
    content: "\e904";
}
.icon-beautycard:before {
    content: "\e905";
}
.icon-calendar:before {
    content: "\e906";
}
.icon-car:before {
    content: "\e907";
}
.icon-card:before {
    content: "\e908";
}
.icon-cat:before {
    content: "\e909";
}
.icon-chevron-down:before {
    content: "\e90a";
}
.icon-chevron-left:before {
    content: "\e90b";
}
.icon-chevron-left-2:before {
    content: "\e90c";
}
.icon-chevron-right:before {
    content: "\e90d";
}
.icon-chevron-right-2:before {
    content: "\e90e";
}
.icon-child:before {
    content: "\e90f";
}
.icon-cutlery:before {
    content: "\e910";
}
.icon-dog:before {
    content: "\e911";
}
.icon-door:before {
    content: "\e912";
}
.icon-facebook:before {
    content: "\e913";
}
.icon-family:before {
    content: "\e914";
}
.icon-help:before {
    content: "\e915";
}
.icon-help-2:before {
    content: "\e916";
}
.icon-history:before {
    content: "\e917";
}
.icon-kids-pool:before {
    content: "\e918";
}
.icon-map:before {
    content: "\e919";
}
.icon-minus:before {
    content: "\e91a";
}
.icon-minus-circle:before {
    content: "\e91b";
}
.icon-night:before {
    content: "\e91c";
}
.icon-open-pool:before {
    content: "\e91d";
}
.icon-parking-lot:before {
    content: "\e91e";
}
.icon-person:before {
    content: "\e91f";
}
.icon-phone:before {
    content: "\e920";
}
.icon-plus:before {
    content: "\e921";
}
.icon-plus-circle:before {
    content: "\e922";
}
.icon-reload:before {
    content: "\e923";
}
.icon-search:before {
    content: "\e924";
}
.icon-star:before {
    content: "\e925";
}
.icon-thermal:before {
    content: "\e926";
}
.icon-thumbs-up:before {
    content: "\e927";
}
.icon-user:before {
    content: "\e928";
}
.icon-wellness:before {
    content: "\e929";
}
.icon-wheelchair:before {
    content: "\e92a";
}
.icon-wifi:before {
    content: "\e92b";
}

.erase-date {
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 15px;
    color: $muted;
    &.detail-date-erase {
        top: 38px;
        right: 20px;
    }
}