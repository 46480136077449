.item {
  border-bottom: 1px solid $gray-light;
  padding: 20px 0;
  &__info {
    float: right;
    @media (max-width: 992px) {
      float: none;
      display: block;
      text-align: center;
    }
    table {
      float: right;
      @media (max-width: 992px) {
        float: none;
        margin: 5px auto;
      }
    }
  }
  &__name {
    margin: 0 0 15px 0;
    font-size: 24px;
    line-height: 1.1;
    font-weight: bold;
    float: left;
    @media (max-width: 992px) {
      margin-top: 10px;
      float: none;
      display: block;
      text-align: center;
    }
  }
  &--highlight {
    /*background: transparentize($highlight, .95);
    border-left: 3px solid $orange;*/

    background: transparentize($highlight, .95);
    border: 2px solid $orange;
    margin-bottom: 15px;
    padding: 10px;
  }
  &__classification {
    position: relative;
    top: -10px;
    /*right: -7px;*/
    white-space: nowrap;
    .icon-star {
      color: $yellow;
      font-size: 17px;
      margin-right: 2px;
    }
  }
  &__image {
    float: left;
    position: relative;
    @media (max-width: 992px) {
      float: none;
      position: relative;
      display: inline-block;
      text-align: center;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__imagecontainer {
    @media (max-width: 992px) {
      text-align: center;
    }
  }
  &__highlight-badge {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 150px;
    height: 150px;
    span {
      padding: 5px 0;
      background: $orange;
      font-size: 13px;
      color: #FFF;
      display: block;
      transform-origin: center;
      transform: rotate(-45deg);
      text-align: center;
      margin-left: -35px;
      margin-top: 35px;
      width: 170px;
    }
  }
  &__location-link {
    position: absolute;
    display: inline-block;
    padding: 7px 10px 4px 10px;
    color: #FFF;
    border: 1px solid rgba(255, 255, 255, .7);
    background: rgba(0, 0, 0, .5);
    border-radius: 3px;
    font-size: 13px;
    right: 10px;
    bottom: 10px;
    i {
      position: relative;
      top: -1px;
    }
  }
  &__content {
    margin-left: 320px;
    position: relative;
    @media (max-width: 992px) {
      margin-left: 0;
      float: none;
    }
  }
  &__info {
    span {
      display: inline-block;
      margin-right: 10px;
      i {
        margin-right: 3px;
      }
      .badge {
        background: $orangeCTA;
        color: #FFF;
      }
    }
  }
  &__features {
    margin: 10px 0 18px;
    i {
      font-size: 19px;
      margin-right: 5px;
    }
  }
  &__morepackages {
    margin: 10px 0 0px;
  }
}

.list-offers {
  margin-bottom: 20px;
  &__item {
    margin: 0 1px 0 0;
    background: $gray-light;
    text-align: center;
    height: 140px;
    padding: 15px;
    a {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a, span, b {
      display: block;
    }
  }
  &__action {
    text-transform: uppercase;
    margin-top: 15px;
  }
  &__price-tag {
    font-weight: bold;
    display: block;
    span {
      display: inline-block;
      position: relative;
    }
  }
}

.price {
  /*font-weight: bold;*/
  display: inline-block;
  &--discount {
    opacity: .8;
    margin-right: 10px;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 48%;
      background: $orange;
      transform: rotate(-12deg);
      transform-origin: center center;
    }
  }
  &--discount--label {
     opacity: .8;
  }
}

.no-results-info {
  background: $blue;
  color: $white;
  padding: 20px;
  font-size: 18px;
  &--icon {
    line-height: 40px;
    font-size: 38px;
    margin: 5px 15px 0 5px;
  }
}

.action {
  background: $orangeCTA;
  color: #FFF;
  padding: 4px 7px 1px 7px;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  transition-duration: .3s;
  font-size: 13px;
  //text-transform: capitalize;
  border: 0;
  cursor: pointer;
  &:hover, &:active, &:focus {
    background: darken($orangeCTA, 10);
    color: #FFF;
  }
  &.blue {
    background: $blue;
    &:hover, &:active, &:focus {
      background: darken($blue, 10);
      color: #FFF;
    }
  }
  &--no-bg {
    background: none;
    color: $orangeCTA;
    margin-top: 10px;
  }
  &.warn {
    background: $yellow;
    &:hover, &:active, &:focus {
      background: darken($yellow, 10);
      color: #FFF;
    }
  }
}
.item__content {
  .action {
    padding: 8px 16px 6px 16px;
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
    margin-top: 10px;
  }
}

.slick-holder {
  height: 140px;
  overflow: hidden;
}